import React, { useEffect, useState, Fragment } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../../../utils/generalFunctions';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// context
import { useAgentAuth } from '../../../context/AgentContext';
import { useAuth } from '../../../context/AuthContext';
import { useLandlord } from '../../../context/LandlordContext';

// components
import AgentDashboardSidebar from '../../Sidebar';
import LandlordMarketingMaterial from '../../../components/LandlordMarketingMaterial';
import DisplayPropertyCard from '../../../components/Properties/DisplayPropertyCard';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  Spacer,
  Badge,
  Box,
  SimpleGrid,
  Spinner,
  Center,
  StackDivider,
  IconButton,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { FaRegTrashAlt } from 'react-icons/fa';

const Properties = () => {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const {
    landlordProperties,
    getLandlordProperties_Truncated,
    getLandlordProperties_Full,
    createPropertyApplication,
    deletePropertyApplication,
  } = useLandlord();
  const { applicationData } = useAgentAuth();
  const toast = useToast();

  const [tabIndex, setTabIndex] = useState(1);

  const [unFinishedApplications, setUnFinishedApplications] = useState([]);
  const [finishedApplications, setFinishedApplications] = useState([]);
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);

  const [activeFinishedApplications, setActiveFinishedApplications] = useState(
    []
  );
  const [inReviewFinishedApplications, setInReviewFinishedApplications] =
    useState([]);
  const [archivedFinishedApplications, setArchivedFinishedApplications] =
    useState([]);

  const handleGetLandlordProperties_Truncated = async () => {
    try {
      setPropertiesLoaded(false);
      const properties = await getLandlordProperties_Truncated(authUser?.uid);
      setUnFinishedApplications(
        properties
          .filter(property => property?.data?.status === 'INCOMPLETE')
          .sort((a, b) => {
            const timeA = b.data?.timeStarted?.toDate?.() || new Date(0);
            const timeB = a.data?.timeStarted?.toDate?.() || new Date(0);
            return timeA - timeB;
          })
      );
      setFinishedApplications(
        properties
          .filter(property => property?.data?.status !== 'INCOMPLETE')
          .sort((a, b) => {
            // First sort by status
            const statusOrder = {
              ACCEPTED: 0,
              IN_REVIEW: 1,
              REJECTED: 2,
            };
            const statusA = statusOrder[a.data?.status];
            const statusB = statusOrder[b.data?.status];

            if (statusA !== statusB) {
              return statusA - statusB;
            }

            // Then sort by date within each status
            const timeA = b.data?.timeSubmitted?.toDate?.() || new Date(0);
            const timeB = a.data?.timeSubmitted?.toDate?.() || new Date(0);
            return timeA - timeB;
          })
      );
      setPropertiesLoaded(true);
    } catch (err) {
      console.log(err);
      setPropertiesLoaded(true);
    }
  };

  const handleGetLandlordProperties_Full = async () => {
    try {
      const properties = await getLandlordProperties_Full(authUser?.uid);
      setUnFinishedApplications(
        properties
          .filter(property => property?.data?.status === 'INCOMPLETE')
          .sort((a, b) => {
            // First sort by status
            const statusOrder = {
              ACCEPTED: 0,
              IN_REVIEW: 1,
              REJECTED: 2,
            };
            const statusA = statusOrder[a.data?.status];
            const statusB = statusOrder[b.data?.status];

            if (statusA !== statusB) {
              return statusA - statusB;
            }

            // Then sort by date within each status
            const timeA = b.data?.timeSubmitted?.toDate?.() || new Date(0);
            const timeB = a.data?.timeSubmitted?.toDate?.() || new Date(0);
            return timeA - timeB;
          })
      );
      setFinishedApplications(
        properties
          .filter(property => property?.data?.status !== 'INCOMPLETE')
          .sort((a, b) => {
            const timeA = b.data?.timeSubmitted?.toDate?.() || new Date(0);
            const timeB = a.data?.timeSubmitted?.toDate?.() || new Date(0);
            return timeA - timeB;
          })
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetLandlordProperties_Truncated();
    };

    fetchData().catch(console.error);
  }, [authUser]);

  useEffect(() => {
    const fetchData = async () => {
      await handleGetLandlordProperties_Full();
    };

    fetchData().catch(console.error);
  }, [authUser]);

  useEffect(() => {
    let active_FinishedApplications = [];
    let inReview_FinishedApplications = [];
    let archived_FinishedApplications = [];

    for (let i = 0; i < finishedApplications.length; i++) {
      const currentApplication = finishedApplications[i];

      if (currentApplication?.data?.status === 'ACCEPTED') {
        active_FinishedApplications.push(currentApplication);
      } else if (currentApplication?.data?.status === 'IN_REVIEW') {
        inReview_FinishedApplications.push(currentApplication);
      } else if (currentApplication?.data?.status === 'REJECTED') {
        archived_FinishedApplications.push(currentApplication);
      }
    }

    setActiveFinishedApplications(active_FinishedApplications);
    setInReviewFinishedApplications(inReview_FinishedApplications);
    setArchivedFinishedApplications(archived_FinishedApplications);
  }, [finishedApplications]);

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Container maxW="container.xl">
        <Flex direction={'column'}>
          <Flex align={'center'} mb="5">
            <Text fontSize="2xl" fontWeight="600">
              My Properties:
            </Text>
            <Spacer />
            <Button
              size={{ base: 'sm', md: 'md' }}
              leftIcon={<AddIcon />}
              colorScheme="blue"
              onClick={async () => {
                try {
                  setPropertiesLoaded(false);
                  const applicationId = await createPropertyApplication();
                  if (applicationId) {
                    navigate(
                      `/agents/properties/add-property/${applicationId}`
                    );
                  } else {
                    toast({
                      title: 'Error',
                      description: 'Error in creating property application',
                      status: 'error',
                      duration: 9000,
                      isClosable: true,
                      position: 'top-right',
                    });
                  }
                  setPropertiesLoaded(true);
                } catch (err) {
                  console.log(err);
                  toast({
                    title: 'Error',
                    description: 'Error in creating property application',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-right',
                  });
                  setPropertiesLoaded(true);
                }
              }}
              isLoading={!propertiesLoaded}
            >
              Add Property
            </Button>
          </Flex>

          {propertiesLoaded ? (
            <Fragment>
              {unFinishedApplications.length == 0 &&
              finishedApplications.length == 0 ? (
                <Card>
                  <CardBody>
                    <Text>No properties listed</Text>
                  </CardBody>
                </Card>
              ) : (
                <Stack spacing={10}>
                  <Box>
                    <Tabs variant="soft-rounded" defaultIndex={1}>
                      <TabList>
                        <Tab>
                          <Text size="md">
                            In-Complete (
                            {unFinishedApplications
                              ? unFinishedApplications.length
                              : 0}
                            )
                          </Text>
                        </Tab>
                        <Tab>
                          <Text size="md">
                            All (
                            {finishedApplications
                              ? finishedApplications.length
                              : 0}
                            )
                          </Text>
                        </Tab>
                        <Tab>
                          <Text size="md">
                            Active (
                            {activeFinishedApplications
                              ? activeFinishedApplications.length
                              : 0}
                            )
                          </Text>
                        </Tab>
                        <Tab>
                          <Text size="md">
                            In-Review (
                            {inReviewFinishedApplications
                              ? inReviewFinishedApplications.length
                              : 0}
                            )
                          </Text>
                        </Tab>
                        <Tab>
                          <Text size="md">
                            Archive (
                            {archivedFinishedApplications
                              ? archivedFinishedApplications.length
                              : 0}
                            )
                          </Text>
                        </Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel>
                          {unFinishedApplications.length === 0 ? (
                            <Card>
                              <CardBody>No in-active listings</CardBody>
                            </Card>
                          ) : (
                            <SimpleGrid columns={1} spacing={5}>
                              {unFinishedApplications.map(property => (
                                <Card>
                                  <CardBody>
                                    <Flex
                                      direction={{ base: 'column', md: 'row' }}
                                      align="center"
                                    >
                                      {property?.data?.stepTwo[3]?.answer ? (
                                        <Text
                                          fontWeight="600"
                                          fontSize={
                                            property?.data?.stepTwo[3]?.answer
                                              ?.length >= 50
                                              ? 'sm'
                                              : 'md'
                                          }
                                        >
                                          {property?.data?.stepTwo[3]?.answer?.slice(
                                            0,
                                            50
                                          ) +
                                            (property?.data?.stepTwo[3]?.answer
                                              ?.length > 50
                                              ? '...'
                                              : '')}
                                        </Text>
                                      ) : (
                                        <Flex align="center" gap={2}>
                                          <Text>Listing Started:</Text>
                                          <Text fontWeight="600">
                                            {property?.data?.timeStarted
                                              ?.toDate()
                                              .toLocaleString('en-US', {
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                              })}
                                          </Text>
                                        </Flex>
                                      )}

                                      <Spacer />
                                      <Flex
                                        gap={3}
                                        w={{ base: '100%', md: 'auto' }}
                                        mt={{ base: 3, md: 0 }}
                                      >
                                        <Button
                                          size="sm"
                                          colorScheme="blue"
                                          w={{ base: '100%', md: 'auto' }}
                                          onClick={() => {
                                            navigate(
                                              `/agents/properties/add-property/${property.id}`
                                            );
                                          }}
                                        >
                                          Resume
                                        </Button>
                                        <IconButton
                                          size="sm"
                                          colorScheme="red"
                                          aria-label="Delete Application"
                                          icon={<FaRegTrashAlt />}
                                          onClick={async () => {
                                            await deletePropertyApplication(
                                              property.id
                                            );
                                            await Promise.all([
                                              handleGetLandlordProperties_Truncated(),
                                              handleGetLandlordProperties_Full(),
                                            ]);
                                          }}
                                        />
                                      </Flex>
                                    </Flex>
                                  </CardBody>
                                </Card>
                              ))}
                            </SimpleGrid>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {finishedApplications.length === 0 ? (
                            <Card>
                              <CardBody>No properties listed</CardBody>
                            </Card>
                          ) : (
                            <SimpleGrid
                              spacing={10}
                              columns={{ base: 1, md: 2 }}
                            >
                              {finishedApplications.map(property => (
                                <DisplayPropertyCard
                                  key={property.id}
                                  property={property}
                                  userType="agent"
                                  authUser={authUser}
                                  applicationData={applicationData}
                                  navigateUrl={`/landlord/property-details/${property.id}/agent`}
                                />
                              ))}
                            </SimpleGrid>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {activeFinishedApplications.length == 0 ? (
                            <Card>
                              <CardBody>No properties listed</CardBody>
                            </Card>
                          ) : (
                            <SimpleGrid
                              spacing={10}
                              columns={{ base: 1, md: 2 }}
                            >
                              {activeFinishedApplications.map(property => (
                                <DisplayPropertyCard
                                  key={property.id}
                                  property={property}
                                  userType="agent"
                                  authUser={authUser}
                                  applicationData={applicationData}
                                  navigateUrl={`/landlord/property-details/${property.id}/agent`}
                                />
                              ))}
                            </SimpleGrid>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {inReviewFinishedApplications.length == 0 ? (
                            <Card>
                              <CardBody>No properties listed</CardBody>
                            </Card>
                          ) : (
                            <SimpleGrid
                              spacing={10}
                              columns={{ base: 1, md: 2 }}
                            >
                              {inReviewFinishedApplications.map(property => (
                                <DisplayPropertyCard
                                  key={property.id}
                                  property={property}
                                  userType="agent"
                                  authUser={authUser}
                                  applicationData={applicationData}
                                  navigateUrl={`/landlord/property-details/${property.id}/agent`}
                                />
                              ))}
                            </SimpleGrid>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {archivedFinishedApplications.length == 0 ? (
                            <Card>
                              <CardBody>No properties listed</CardBody>
                            </Card>
                          ) : (
                            <SimpleGrid
                              spacing={10}
                              columns={{ base: 1, md: 2 }}
                            >
                              {archivedFinishedApplications.map(property => (
                                <DisplayPropertyCard
                                  key={property.id}
                                  property={property}
                                  userType="agent"
                                  authUser={authUser}
                                  applicationData={applicationData}
                                  navigateUrl={`/landlord/property-details/${property.id}/agent`}
                                />
                              ))}
                            </SimpleGrid>
                          )}
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Box>
                </Stack>
              )}
            </Fragment>
          ) : (
            <Text>
              <Card>
                <CardBody>
                  <Center>
                    <Spinner />
                  </Center>
                </CardBody>
              </Card>
            </Text>
          )}
        </Flex>
      </Container>
    </AgentDashboardSidebar>
  );
};

export default Properties;
