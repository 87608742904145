import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

// chakra
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const FaqCard = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Card align="center" bg="white" size="md">
        <CardHeader align="center">
          <Heading as="h4" size="md" mb="1">
            Frequently Asked Questions
          </Heading>
          <Text fontSize="md" fontWeight="600" color="gray">
            Have a question? We might have an answer for you.
          </Text>
        </CardHeader>
        <CardBody w="100%">
          <Stack spacing="8" w="100%">
            <Accordion allowMultiple textAlign="left" w="100%">
              <AccordionItem w="100%">
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize="lg" fontWeight="600">
                        What is Utopia?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  Utopia is America's 1st rent to own exchange for approved
                  customers, landlords and agents. So, on one side, we help our
                  customers achieve their homeownership goals faster and on the
                  other side, we help our landlords generate far higher (up to
                  2X) returns from their rental properties. Once our customer
                  selects their dream home, we do a 3 year “rent to own”
                  agreement between the customer and the landlord. During this
                  time, we help the customer become mortgage ready by helping
                  them improve their credit & save enough for a mortgage.
                  Similarly, we help our landlords achieve higher yield, lower
                  repair and maintenance expenses, timely rent payments, reduced
                  vacancy rates, and minimal closing costs.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize="lg" fontWeight="600">
                        What is Utopia Wallet?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  When you rent through Utopia, you have the option but not the
                  obligation to buy your rental home by signing a purchase
                  option agreement with the landlord. You get a Wallet where
                  your savings and earnings sit and grow every month. Your
                  Utopia Wallet can grow in 3 ways:
                  <br />
                  <br />
                  1) Your contributions: When you sign the lease, you do not
                  make any security deposit but you make an initial contribution
                  of <b>1 to 3 percent</b> of Price of Property (also called
                  purchasing option premium). During the lease, you can make
                  additional monthly or one off contributions so as to save for
                  your down payment to buy the home.
                  <br />
                  <br />
                  2) Profit Sharing in Rent: Every month you earn a share of the
                  rental profits. These rental profits are added to your wallet
                  on a monthly basis and further grow your wallet.
                  <br />
                  <br />
                  3) Home Price Appreciation: With our innovative program, you
                  also earn a portion of the increase in home value during the
                  term of the lease. So, As the value of your rental home
                  increases, the value of your wallet also increases to make you
                  downpayment ready sooner.
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize="lg" fontWeight="600">
                        How can I get started with Utopia?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <b>For Customers:</b> If you have a credit score of at least
                  550 and earn $3,500 per month or more then we encourage you to{' '}
                  <Link
                    color="blue"
                    fontWeight="bold"
                    onClick={() => {
                      navigate('/accounts');
                    }}
                  >
                    apply
                  </Link>{' '}
                  (It’s free) and get pre-qualified. We also take into account
                  DTI Ratio and timely payment history to ensure that you would
                  get a mortgage in the next 2-3 years. You can find your dream
                  home from{' '}
                  <Link
                    color="blue"
                    fontWeight="bold"
                    onClick={() => {
                      navigate('/search-homes');
                    }}
                  >
                    our listings
                  </Link>
                  . Once you select a home, we do the due diligence on the
                  selected home and if everything checks out then we do a “rent
                  to own” agreement between the landlord and you. At this stage,
                  You will be asked to contribute up to 3% of the Home price
                  into your Utopia Wallet. This money gets credited toward your
                  down payment when you are mortgage ready to buy the property
                  back from the Landlord.
                  <br />
                  <br />
                  <b>For Landlords:</b> if you have a single family home or Town
                  home in ready to move in condition then you can{' '}
                  <Link
                    color="blue"
                    fontWeight="bold"
                    onClick={() => {
                      navigate('/accounts');
                    }}
                  >
                    list your rental property on our platform
                  </Link>{' '}
                  for free. We match you with a Utopia approved “rent to own”
                  customer who is capable of paying the monthly rent and
                  interested in buying back your property in 3 years or less.
                  The rent, current market price and future buyback price are
                  all determined beforehand based on Utopia’s market research
                  and after our in-depth conversations with you. Our landlords
                  generally achieve higher returns (Up to twice as much), lower
                  repair and maintenance expenses, timely rent payments, reduced
                  vacancy rates, and minimal closing costs
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontSize="lg" fontWeight="600">
                        What if I choose NOT to buy the home at the end of the
                        lease agreement?
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  We do understand that sometimes life happens. So, if our
                  customer chooses not to buy the home at the end of the lease
                  agreement then the customer gets back all the monthly savings
                  contributions in the wallet. The customer will only lose the
                  3% that he/she had put down to partially cover the landlord's
                  re-selling cost of the home.
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            <Flex>
              <Spacer />
              <Button
                rightIcon={<ArrowForwardIcon />}
                colorScheme="blue"
                variant="ghost"
                onClick={() => {
                  window.open(
                    'https://www.utopiahomes.us/faq/general-questions'
                  );
                }}
              >
                View More
              </Button>
            </Flex>
          </Stack>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default FaqCard;
