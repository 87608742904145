import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Heading,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { useState } from 'react';
import AgentDashboardSidebar from '../../../Sidebar';

const questions = [
  'How do I help my clients to make the offer using Utopia?',
  'How do I help my Sellers/ Landlords to get their home sold/ rented?',
  'How to change the custom link to invite Home-Buyers, Sellers & agents?',
];

const answers = [
  [
    {
      step: 'Step 1',
      description:
        'Invite Clients, who fulfil our Home Buyers Criteria, either by sending an email invite or by sharing your customised link, obtained from your agent portal. All your customers who sign up using your invite will be attributed to you.',
    },
    {
      step: 'Step 2',
      description:
        'Check-in and Help the clients to sign-up and complete the free application (takes <5 mins), to get pre-qualified. Once your client gets pre-qualified and confirms you as the agent of choice, you will be able to track the status of their application in your agent portal.',
    },
    {
      step: 'Step 3',
      description:
        ' If there are any missing documents or information, a dedicated relationship manager will reach out to the client and will inform you as well. Help the clients to promptly share the requested documents and or information.',
    },
    {
      step: 'Step 4',
      description:
        'We review the application and let the client and you know of the approval status. If approved, the client is given a certain home buying budget as given in the approval letter. This letter is valid for 30-60 days (varies from case to case) and can be easily extended if the client needs more time to find their preferred home.',
    },
    {
      step: 'Step 5',
      description:
        'Once the client is approved, Please start shopping for Client’s Utopia within the approved home buying budget on the “Search Homes” Section of our website. Please check our Utopia’s Eligible Homes Criteria to ensure that the selected home meets the criteria.',
    },
    {
      step: 'Step 6',
      description:
        'Once the client has identified their preferred home, please email support@Utopia.cx the subject property details along with CMA of at least 5 comparable sales from the last 3 months in 2 miles radius of Subject Property and at least 5 comparable rentals from the last 6 months in 2 miles radius of Subject Property, Please expand criteria if not enough comparables are available.',
    },
    {
      step: 'Step 7',
      description:
        'Based on your feedback, client suggestions, CMA and Utopia’s internal analysis, we will discuss the details with you and the seller/landlord. After that, we come up with a price agreeable to the seller/ landlord & Rent to Own Customer.',
    },
    {
      step: 'Step 8',
      description:
        'Once the offer price is finalised and as we initiate signing the offer letter, We ask the client to do 2 things on priority:',
      substeps: [
        ' 1. Review and sign the Utopia Occupant Proposal, stating that they are in agreement with the purchase price and associated payments.',
        '2. Pay an offer initiation deposit prior to our  making the first offer.',
      ],
    },
    {
      step: 'Step 9',
      description:
        'After this, with your help, we get the purchase agreement signed between the landlord/ seller and rent- to-own customer.',
    },
    {
      step: 'Step 10',
      description:
        'Once all the formalities are completed, You can do the final walk-thru with the customer and get them the possession of their new home. For any more details/questions, you can reach us via Email (support@Utopia.cx) or Phone: 844-957-3700',
    },
  ],

  [
    {
      step: 'Step 1',
      description:
        'Invite Sellers/ Landlords, either by sending an email invite or by sharing your customised link, obtained from your agent portal. All your customers who sign up using your invite will be attributed to you.',
    },
    {
      step: 'Step 2',
      description:
        'Assist the landlord with signing up and completing the free listing (takes less than 5 minutes). Ensure the home is competitively priced for sale and the monthly rental aligns with the local market for “rent to own” homes. Typically, the landlords that work with us are able to make much higher returns through higher rental yield, longer lease terms and far lower repair and maintenance cost. ',
    },
    {
      step: 'Step 3',
      description:
        'Once the listing is approved by Utopia, it will be live on our website. From your agent portal, you can download a social media flyer to promote the property across platforms like Instagram, X, Facebook, LinkedIn, TikTok, and messaging groups such as iMessage, WhatsApp, and Telegram. Additionally, you can download and print a PDF marketing flyer for local distribution. All marketing materials include your details and a unique QR code, ensuring customers are attributed to you, with their progress easily trackable. ',
    },
    {
      step: 'Step 4',
      description:
        'You will also start getting pre-approved, underwritten customers visiting our website with home buying budgets aligned with the listing. So, you can start showing them the home at a convenient time.',
    },
    {
      step: 'Step 5',
      description:
        'Once a customer selects a home, notify us to facilitate the formalities between the landlord/seller and the homebuyer/customer. We’ll finalize the “rent-to-own” agreement, which allows the customer to purchase the home at a pre-determined price within the lease period. Once all the formalities are completed, you collect your full commission and any applicable incentives.',
    },
  ],

  [
    {
      step: 'Step 1',
      description:
        "Click on the 'Invite', button which will be on the left hand side of your portal. It will be the third button from the top.",
    },
    {
      step: 'Step 2',
      description:
        'After Clicking, There will be a "Update Code" button on the section marked as "Invite By Sharing Your Link". Please click on the "Update Code" button.',
    },
    {
      step: 'Step 3',
      description:
        'Once you click, a pop-up would appear asking you to enter your preferred name. Please enter your preferred name and click on "Update Code", which will be right below.',
    },
    {
      step: 'Step 4',
      description: 'This will change your Link.',
    },
  ],
];

const Faq = () => {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleAccordionClick = index => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  const handleClick = () => {
    window.open('https://www.utopiahomes.us/faq/general-questions', '_blank');
  };

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Box p={6}>
        <Heading as="h2" size="lg" mb={6} textAlign={'center'}>
          Agent FAQs
        </Heading>
        <Accordion allowMultiple index={expandedIndex}>
          {questions.map((question, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton onClick={() => handleAccordionClick(index)}>
                  <Box flex="1" textAlign="left" py={3}>
                    <Text fontSize={'lg'}>{question}</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {answers[index].map((answer, subIndex) => (
                  <Box key={subIndex} pl={4}>
                    <Stack>
                      <Stack direction="row" spacing={4} align="center">
                        <Badge colorScheme="blue">{answer.step}</Badge>
                        <Text>{answer.description}</Text>
                      </Stack>

                      <UnorderedList pl={20}>
                        {answer.substeps?.map((step, ind) => {
                          return <Text ke={ind}>{step}</Text>;
                        })}
                      </UnorderedList>
                    </Stack>
                  </Box>
                ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        <Button size="md" onClick={handleClick} mt={5}>
          See More {'>'}
        </Button>
      </Box>
    </AgentDashboardSidebar>
  );
};

export default Faq;
