import React, { Fragment, useState, forwardRef } from 'react';
import {
  isEmptyString,
  isIos,
  isSafari,
} from '../../../../utils/generalFunctions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// utils
import { FullStatesList } from '../../../../utils/states';

// chakra
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Container,
  Image,
  Stack,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Progress,
  RadioGroup,
  Radio,
  Textarea,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Spinner,
  Spacer,
} from '@chakra-ui/react';
import { CalendarIcon } from '@chakra-ui/icons';

const HomebuyerStepTen = ({
  setActiveStep,
  stepTenEmployerName,
  setStepTenEmployerName,
  stepTenStartDate,
  setStepTenStartDate,
  stepTenEmployerCity,
  setStepTenEmployerCity,
  stepTenEmployerState,
  setStepTenEmployerState,
  handleNext,
  loading,
}) => {
  const dateIsValid = date => {
    return date instanceof Date && !isNaN(date);
  };

  const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      borderWidth="2px"
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      w="100%"
      size="lg"
      variant="ghost"
    >
      <Flex w="100%" align="center">
        {value === null || value === '' ? (
          <Text color="gray.400">mm/dd/yyyy</Text>
        ) : (
          <Text>{value}</Text>
        )}
        <Spacer />
        <CalendarIcon />
      </Flex>
    </Button>
  ));

  return (
    <Fragment>
      <Stack spacing="5">
        <Progress
          colorScheme="blue"
          rounded="full"
          size="md"
          value={(10 / 18) * 100}
          bg="gray.200"
          hasStripe
          isAnimated
        />
        <Card bg="white" size="lg">
          <CardBody>
            <Stack spacing="8">
              <Box>
                <Box mb="5">
                  <Heading as="h4" size="md" mb="1">
                    Employer's details
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    We need some details about your employer to verify your
                    income.
                  </Text>
                </Box>

                <Stack spacing="5">
                  <FormControl>
                    <FormLabel color="gray">Employer's name:</FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepTenEmployerName}
                      onChange={e => {
                        setStepTenEmployerName(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">Start date:</FormLabel>
                    {/* {isIos() ? (
                      <DatePicker
                        selected={stepTenStartDate}
                        onChange={date => setStepTenStartDate(date)}
                        minDate={'1950-01-01T04:49:20.366Z'}
                        maxDate={new Date()}
                        placeholderText="mm/dd/yyyy"
                        customInput={<DateCustomInput />}
                      />
                    ) : (
                      <Input
                        id="startDateDateInput"
                        borderWidth="2px"
                        size="lg"
                        type="date"
                        placeholder="mm/dd/yyyy"
                        min="1950-01-01"
                        max={new Date().toISOString().split('T')[0]}
                        onKeyDown={e => e.preventDefault()}
                        value={stepTenStartDate}
                        onChange={e => {
                          setStepTenStartDate(e.target.value);
                        }}
                      />
                    )} */}
                    <Input
                      id="startDateDateInput"
                      borderWidth="2px"
                      size="lg"
                      type="date"
                      placeholder="mm/dd/yyyy"
                      min="1950-01-01"
                      max={new Date().toISOString().split('T')[0]}
                      onKeyDown={e => e.preventDefault()}
                      value={stepTenStartDate}
                      onChange={e => {
                        setStepTenStartDate(e.target.value);
                      }}
                    />

                    <FormHelperText color="gray.400">
                      If employee has had a job for less than 3 months, they
                      will need to show previous income sources.
                    </FormHelperText>
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Employer location - City:
                    </FormLabel>
                    <Input
                      borderWidth="2px"
                      size="lg"
                      type="text"
                      value={stepTenEmployerCity}
                      onChange={e => {
                        setStepTenEmployerCity(e.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel color="gray">
                      Employer location - State:
                    </FormLabel>
                    <Select
                      borderWidth="2px"
                      size="lg"
                      placeholder="Select state"
                      value={stepTenEmployerState}
                      onChange={e => {
                        setStepTenEmployerState(e.target.value);
                      }}
                    >
                      {FullStatesList.map(state => {
                        return (
                          <option key={state.abbreviation} value={state.name}>
                            {state.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
            </Stack>
          </CardBody>
        </Card>
        <Stack justify="right" direction="row" spacing="3">
          <Button
            onClick={() => {
              setActiveStep(9);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              setStepTenEmployerName(stepTenEmployerName.trim());
              setStepTenEmployerCity(stepTenEmployerCity.trim());
              handleNext();
            }}
            isDisabled={
              isEmptyString(stepTenEmployerName) ||
              isEmptyString(stepTenStartDate) ||
              !dateIsValid(new Date(stepTenStartDate)) ||
              isEmptyString(stepTenEmployerCity) ||
              isEmptyString(stepTenEmployerState) ||
              stepTenEmployerName.trim().length === 1 ||
              stepTenEmployerCity.trim().length === 1
            }
          >
            {loading ? <Spinner /> : 'Next'}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
};

export default HomebuyerStepTen;
