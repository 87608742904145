/*
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// context
import { useAgentAuth } from '../../../context/AgentContext';

// components
import AgentDashboardSidebar from '../../Sidebar';
import GenericTable from '../../../components/GenericTable';

// chakra
import {
  Container,
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

const LandlordProperties = () => {
  const navigate = useNavigate();

  const {
    searchResults,
    getAgentInvitedLandlordProperties,
    invitedLandlordProperties,
  } = useAgentAuth();

  const [status, setStatus] = useState('Started');
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);

  const columns = React.useMemo(() => {
    return status === 'Started'
      ? [
          {
            Header: 'Property Address',
            accessor: 'propertyAddress',
            minWidth: 150,
          },
          {
            Header: 'Email',
            accessor: 'email',
            minWidth: 150,
            // disableSortBy: true,
          },

          {
            Header: 'Started At',
            accessor: 'timeStarted',
            minWidth: 150,

            Cell: ({ value }) => moment(value).fromNow(),
            sortType: (a, b) => {
              const dateA = a.values.timeStarted
                ? new Date(a.values.timeStarted)
                : undefined;
              const dateB = b.values.timeStarted
                ? new Date(b.values.timeStarted)
                : undefined;
              return dateA && dateB ? dateA - dateB : 0;
            },
          },
          {
            Header: 'Property Status',
            accessor: 'status',
            minWidth: 150,
            // disableSortBy: true,
          },
          {
            Header: 'Actions',
            accessor: 'id',
            minWidth: 150,
            disableSortBy: true,
            isActionableButton: true,
            buttonTitle: 'View',
            buttonAction: value => {
              navigate(`/landlord/property-details/${value?.id}/agent`);
            },
          },
        ]
      : [
          {
            Header: 'Property Address',
            accessor: 'propertyAddress',
            minWidth: 150,
          },
          {
            Header: 'Email',
            accessor: 'email',
            minWidth: 150,
            // disableSortBy: true,
          },

          {
            Header: 'Time Submitted',
            accessor: 'timeSubmitted',
            minWidth: 150,

            Cell: ({ value }) => moment(value).fromNow('DD/MM/YYYY'),
            sortType: (a, b) => {
              const dateA = a.values.timeSubmitted
                ? new Date(a.values.timeSubmitted)
                : undefined;
              const dateB = b.values.timeSubmitted
                ? new Date(b.values.timeSubmitted)
                : undefined;
              return dateA && dateB ? dateA - dateB : 0;
            },
          },
          {
            Header: 'Property Status',
            accessor: 'status',
            minWidth: 150,
            // disableSortBy: true,
          },
          {
            Header: 'Actions',
            accessor: 'id',
            minWidth: 150,
            disableSortBy: true,
            isActionableButton: true,
            buttonTitle: 'View',
            buttonAction: value => {
              navigate(`/landlord/property-details/${value?.id}/agent`);
            },
          },
        ];
  }, [status]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const data = await getAgentInvitedLandlordProperties();

      let filteredData = [];
      for (let i = 0; i < data.length; i++) {
        const property = data[i];

        if (status === 'Started') {
          if (property.status === 'INCOMPLETE') {
            filteredData.push(property);
          }
        } else if (status === 'In Review') {
          if (property.status === 'IN_REVIEW') {
            filteredData.push(property);
          }
        } else if (status === 'Accepted') {
          if (property.status === 'ACCEPTED') {
            filteredData.push(property);
          }
        } else if (status === 'Rejected') {
          if (property.status === 'REJECTED') {
            filteredData.push(property);
          }
        }
      }

      let cleanData = [];
      for (let i = 0; i < filteredData.length; i++) {
        const property = filteredData[i];

        cleanData.push({
          id: property?.id,
          email: property?.email,
          status: property?.status,
          timeStarted: property?.timeStarted,
          timeSubmitted: property?.timeSubmitted,
          timeStartedFormatted: property?.timeStartedFormatted,
          timeSubmittedFormatted: property?.timeSubmittedFormatted,
          propertyAddress: property?.stepTwo[3]?.answer,
        });
      }

      if (status === 'Started') {
        cleanData.sort((a, b) => {
          if (!a.timeStarted || !b.timeStarted) return 0;
          return b.timeStarted - a.timeStarted;
        });
      } else if (
        status === 'In Review' ||
        status === 'Accepted' ||
        status === 'Rejected'
      ) {
        cleanData.sort((a, b) => {
          if (!a.timeSubmitted || !b.timeSubmitted) return 0;
          return b.timeSubmitted - a.timeSubmitted;
        });
      }

      setPropertyData(cleanData);

      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [status]);

  return (
    <Fragment>
      <AgentDashboardSidebar showHeader={false}>
        <Container maxW="container.2xl">
          <Tabs>
            <TabList>
              <Tab onClick={() => setStatus('Started')}>Started</Tab>
              <Tab onClick={() => setStatus('In Review')}>In Review</Tab>
              <Tab onClick={() => setStatus('Accepted')}>Accepted</Tab>
              <Tab onClick={() => setStatus('Rejected')}>Rejected</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Flex direction={'column'}>
                  <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>
                      Landlord Properties: {status}
                      {loading ? ' Loading...' : ` (${propertyData.length})`}
                    </Text>
                  </Box>

                  {loading ? (
                    <Flex justifyContent={'center'}>
                      <Spinner />
                    </Flex>
                  ) : (
                    <GenericTable
                      columns={columns}
                      data={propertyData}
                      loading={loading}
                      isAgent={false}
                      isLandlord={true}
                      detailsDisabled={true}
                    />
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex direction={'column'}>
                  <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>
                      Landlord Properties: {status} ({propertyData.length})
                    </Text>
                  </Box>

                  {loading ? (
                    <Flex justifyContent={'center'}>
                      <Spinner />
                    </Flex>
                  ) : (
                    <GenericTable
                      columns={columns}
                      data={propertyData}
                      loading={loading}
                      isAgent={false}
                      isLandlord={true}
                      detailsDisabled={true}
                    />
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex direction={'column'}>
                  <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>
                      Landlord Properties: {status} ({propertyData.length})
                    </Text>
                  </Box>

                  {loading ? (
                    <Flex justifyContent={'center'}>
                      <Spinner />
                    </Flex>
                  ) : (
                    <GenericTable
                      columns={columns}
                      data={propertyData}
                      loading={loading}
                      isAgent={false}
                      isLandlord={true}
                      detailsDisabled={true}
                    />
                  )}
                </Flex>
              </TabPanel>
              <TabPanel>
                <Flex direction={'column'}>
                  <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>
                      Landlord Properties: {status} ({propertyData.length})
                    </Text>
                  </Box>

                  {loading ? (
                    <Flex justifyContent={'center'}>
                      <Spinner />
                    </Flex>
                  ) : (
                    <GenericTable
                      columns={columns}
                      data={propertyData}
                      loading={loading}
                      isAgent={false}
                      isLandlord={true}
                      detailsDisabled={true}
                    />
                  )}
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </AgentDashboardSidebar>
    </Fragment>
  );
};

export default LandlordProperties;
*/

import React, { Fragment, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// context
import { useAgentAuth } from '../../../context/AgentContext';

// components
import AgentDashboardSidebar from '../../Sidebar';
import GenericTable from '../../../components/GenericTable';

// chakra
import {
  Container,
  Flex,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';

const LandlordProperties = () => {
  const navigate = useNavigate();

  const {
    searchResults,
    getAgentInvitedLandlordProperties,
    invitedLandlordProperties,
  } = useAgentAuth();

  const [loading, setLoading] = useState(false);
  const [inCompleteProperties, setIncompleteProperties] = useState([]);
  const [inReviewProperties, setInReviewProperties] = useState([]);
  const [acceptedProperties, setAcceptedProperties] = useState([]);
  const [rejectedProperties, setRejectedProperties] = useState([]);

  const startedPropertiesColumn = [
    {
      Header: 'Property Address',
      accessor: 'propertyAddress',
      minWidth: 150,
    },
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 150,
      // disableSortBy: true,
    },

    {
      Header: 'Started At',
      accessor: 'timeStarted',
      minWidth: 150,

      Cell: ({ value }) => moment(value).fromNow(),
      sortType: (a, b) => {
        const dateA = a.values.timeStarted
          ? new Date(a.values.timeStarted)
          : undefined;
        const dateB = b.values.timeStarted
          ? new Date(b.values.timeStarted)
          : undefined;
        return dateA && dateB ? dateA - dateB : 0;
      },
    },
    {
      Header: 'Property Status',
      accessor: 'status',
      minWidth: 150,
      // disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      minWidth: 150,
      disableSortBy: true,
      isActionableButton: true,
      buttonTitle: 'View',
      buttonAction: value => {
        navigate(`/landlord/property-details/${value?.id}/agent`);
      },
    },
  ];

  const otherPropertiesColumn = [
    {
      Header: 'Property Address',
      accessor: 'propertyAddress',
      minWidth: 150,
    },
    {
      Header: 'Email',
      accessor: 'email',
      minWidth: 150,
      // disableSortBy: true,
    },

    {
      Header: 'Time Submitted',
      accessor: 'timeSubmitted',
      minWidth: 150,

      Cell: ({ value }) => moment(value).fromNow('DD/MM/YYYY'),
      sortType: (a, b) => {
        const dateA = a.values.timeSubmitted
          ? new Date(a.values.timeSubmitted)
          : undefined;
        const dateB = b.values.timeSubmitted
          ? new Date(b.values.timeSubmitted)
          : undefined;
        return dateA && dateB ? dateA - dateB : 0;
      },
    },
    {
      Header: 'Property Status',
      accessor: 'status',
      minWidth: 150,
      // disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'id',
      minWidth: 150,
      disableSortBy: true,
      isActionableButton: true,
      buttonTitle: 'View',
      buttonAction: value => {
        navigate(`/landlord/property-details/${value?.id}/agent`);
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const data = await getAgentInvitedLandlordProperties();

      let incompleteProperties = [];
      let inreviewProperties = [];
      let acceptedProperties = [];
      let rejectedProperties = [];

      for (let i = 0; i < data.length; i++) {
        const property = data[i];

        if (property.status === 'INCOMPLETE') {
          incompleteProperties.push(property);
        } else if (property.status === 'IN_REVIEW') {
          inreviewProperties.push(property);
        } else if (property.status === 'ACCEPTED') {
          acceptedProperties.push(property);
        } else if (property.status === 'REJECTED') {
          rejectedProperties.push(property);
        }
      }

      let incompleteProperties_cleaned = [];
      let inreviewProperties_cleaned = [];
      let acceptedProperties_cleaned = [];
      let rejectedProperties_cleaned = [];

      for (let i = 0; i < incompleteProperties.length; i++) {
        incompleteProperties_cleaned.push({
          id: incompleteProperties[i]?.id,
          email: incompleteProperties[i]?.email,
          status: incompleteProperties[i]?.status,
          timeStarted: incompleteProperties[i]?.timeStarted,
          timeSubmitted: incompleteProperties[i]?.timeSubmitted,
          timeStartedFormatted: incompleteProperties[i]?.timeStartedFormatted,
          timeSubmittedFormatted:
            incompleteProperties[i]?.timeSubmittedFormatted,
          propertyAddress: incompleteProperties[i]?.stepTwo[3]?.answer,
        });
      }

      for (let i = 0; i < inreviewProperties.length; i++) {
        inreviewProperties_cleaned.push({
          id: inreviewProperties[i]?.id,
          email: inreviewProperties[i]?.email,
          status: inreviewProperties[i]?.status,
          timeStarted: inreviewProperties[i]?.timeStarted,
          timeSubmitted: inreviewProperties[i]?.timeSubmitted,
          timeStartedFormatted: inreviewProperties[i]?.timeStartedFormatted,
          timeSubmittedFormatted: inreviewProperties[i]?.timeSubmittedFormatted,
          propertyAddress: inreviewProperties[i]?.stepTwo[3]?.answer,
        });
      }

      for (let i = 0; i < acceptedProperties.length; i++) {
        acceptedProperties_cleaned.push({
          id: acceptedProperties[i]?.id,
          email: acceptedProperties[i]?.email,
          status: acceptedProperties[i]?.status,
          timeStarted: acceptedProperties[i]?.timeStarted,
          timeSubmitted: acceptedProperties[i]?.timeSubmitted,
          timeStartedFormatted: acceptedProperties[i]?.timeStartedFormatted,
          timeSubmittedFormatted: acceptedProperties[i]?.timeSubmittedFormatted,
          propertyAddress: acceptedProperties[i]?.stepTwo[3]?.answer,
        });
      }

      for (let i = 0; i < rejectedProperties.length; i++) {
        rejectedProperties_cleaned.push({
          id: rejectedProperties[i]?.id,
          email: rejectedProperties[i]?.email,
          status: rejectedProperties[i]?.status,
          timeStarted: rejectedProperties[i]?.timeStarted,
          timeSubmitted: rejectedProperties[i]?.timeSubmitted,
          timeStartedFormatted: rejectedProperties[i]?.timeStartedFormatted,
          timeSubmittedFormatted: rejectedProperties[i]?.timeSubmittedFormatted,
          propertyAddress: rejectedProperties[i]?.stepTwo[3]?.answer,
        });
      }

      incompleteProperties_cleaned.sort((a, b) => {
        if (!a.timeStarted || !b.timeStarted) return 0;
        return b.timeStarted - a.timeStarted;
      });

      inreviewProperties_cleaned.sort((a, b) => {
        if (!a.timeSubmitted || !b.timeSubmitted) return 0;
        return b.timeSubmitted - a.timeSubmitted;
      });

      acceptedProperties_cleaned.sort((a, b) => {
        if (!a.timeSubmitted || !b.timeSubmitted) return 0;
        return b.timeSubmitted - a.timeSubmitted;
      });

      rejectedProperties_cleaned.sort((a, b) => {
        if (!a.timeSubmitted || !b.timeSubmitted) return 0;
        return b.timeSubmitted - a.timeSubmitted;
      });

      setIncompleteProperties(incompleteProperties_cleaned);
      setInReviewProperties(inreviewProperties_cleaned);
      setAcceptedProperties(acceptedProperties_cleaned);
      setRejectedProperties(rejectedProperties_cleaned);

      setLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Fragment>
      <AgentDashboardSidebar showHeader={false}>
        <Container maxW="container.2xl">
          <Box>
            <Text fontSize={'2xl'} fontWeight={'bold'}>
              Landlord Properties
            </Text>
          </Box>
          <Tabs mt="4" variant="soft-rounded">
            <TabList
              overflowX="auto"
              whiteSpace="nowrap"
              css={{
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': { display: 'none' },
              }}
            >
              <Tab>
                Started (
                {inCompleteProperties?.length
                  ? inCompleteProperties?.length
                  : 0}
                )
              </Tab>
              <Tab>
                In Review (
                {inReviewProperties?.length ? inReviewProperties?.length : 0})
              </Tab>
              <Tab>
                Accepted (
                {acceptedProperties?.length ? acceptedProperties?.length : 0})
              </Tab>
              <Tab>
                Rejected (
                {rejectedProperties?.length ? rejectedProperties?.length : 0})
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <GenericTable
                  columns={startedPropertiesColumn}
                  data={inCompleteProperties}
                  loading={loading}
                  isAgent={false}
                  isLandlord={true}
                  detailsDisabled={true}
                />
              </TabPanel>
              <TabPanel>
                <GenericTable
                  columns={otherPropertiesColumn}
                  data={inReviewProperties}
                  loading={loading}
                  isAgent={false}
                  isLandlord={true}
                  detailsDisabled={true}
                />
              </TabPanel>
              <TabPanel>
                <GenericTable
                  columns={otherPropertiesColumn}
                  data={acceptedProperties}
                  loading={loading}
                  isAgent={false}
                  isLandlord={true}
                  detailsDisabled={true}
                />
              </TabPanel>
              <TabPanel>
                <GenericTable
                  columns={otherPropertiesColumn}
                  data={rejectedProperties}
                  loading={loading}
                  isAgent={false}
                  isLandlord={true}
                  detailsDisabled={true}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </AgentDashboardSidebar>
    </Fragment>
  );
};

export default LandlordProperties;
