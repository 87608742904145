/* eslint-disable */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAgentAuth } from '../../context/AgentContext';

export const SidebarLinks = props => {
  const { accordianIndex, setAccordianIndex } = useAgentAuth();

  const {
    invitedUsers,
    startedApplications,
    offersData,
    closingsData,
    approvedApplications,
    conditionallyApprovedApplications,
    preQualifiedApplications,
    inactive,
    allUsers,
    pausedApplications,
    rejectedApplications,
    invitedAgents,
    initiatedOffers,
    invitedLandlords,
    invitedLandlordProperties,
  } = useAgentAuth();

  let location = useLocation();
  let activeColor = useColorModeValue('#FFFFFF', 'white');
  let inactiveColor = useColorModeValue('#BCC2D0', 'secondaryGray.600');
  let activeIcon = useColorModeValue('#FFFFFF', 'white');
  let textColor = useColorModeValue('#BCC2D0', 'white');
  let brandColor = useColorModeValue('brand.500', 'brand.400');

  const { routes } = props;

  const activeRoute = routeName => {
    return location.pathname === routeName;
  };

  const handleAccordionChange = id => {
    setAccordianIndex(id);
  };

  const getCurrentRouteCount = pathname => {
    let arr = [];

    if (pathname === '/agents/clients') {
      arr = allUsers;
    } else if (pathname === '/agents/applications/invited') {
      arr = invitedUsers;
    } else if (pathname === '/agents/applications/application_started') {
      arr = startedApplications;
    } else if (pathname === '/agents/applications/prequalified') {
      arr = preQualifiedApplications;
    } else if (pathname === '/agents/applications/conditionally_approved') {
      arr = conditionallyApprovedApplications;
    } else if (pathname === '/agents/applications/fully_approved') {
      arr = approvedApplications;
    } else if (pathname === '/agents/applications/inreview') {
      arr = offersData?.inreview;
    } else if (pathname === '/agents/applications/more_info') {
      arr = offersData?.moreInfoRequired;
    } else if (pathname === '/agents/closings/inspection_complete') {
      arr = closingsData?.inspectionComplete;
    } else if (pathname === '/agents/closings/preclosing_complete') {
      arr = closingsData?.preclosingComplete;
    } else if (pathname === '/agents/offers/offer_accepted') {
      arr = offersData?.offerAccepted;
    } else if (pathname === '/agents/offers/offer_extended') {
      arr = offersData?.offerExtended;
    } else if (pathname === '/agents/closings/offer_closed') {
      arr = closingsData?.offerClosed;
    } else if (pathname === '/agents/inactive') {
      arr = inactive;
    } else if (pathname === '/agents/paused') {
      arr = pausedApplications;
    } else if (pathname === '/agents/rejected') {
      arr = rejectedApplications;
    } else if (pathname === '/agents/invites') {
      arr = invitedAgents;
    } else if (pathname === '/agents/offers/initiated') {
      arr = initiatedOffers;
    } else if (pathname === '/agents/landlord/invites') {
      arr = invitedLandlords;
    } else if (pathname === '/agents/landlord/properties/started') {
      arr = invitedLandlordProperties.filter(
        property => property.status === 'INCOMPLETE'
      );
    } else if (pathname === '/agents/landlord/properties/in_review') {
      arr = invitedLandlordProperties.filter(
        property => property.status === 'IN_REVIEW'
      );
    } else if (pathname === '/agents/landlord/properties/rejected') {
      arr = invitedLandlordProperties.filter(
        property => property.status === 'REJECTED'
      );
    } else if (pathname === '/agents/landlord/properties/accepted') {
      arr = invitedLandlordProperties.filter(
        property => property.status === 'ACCEPTED'
      );
    }

    return arr?.length ? arr?.length : 0;
  };

  const RouteComp = ({ route, index }) => {
    if (route.subRoutes?.length > 0) {
      const length = getCurrentRouteCount(route.path);
      const hasLength = !route.subRoutes.length > 0;

      return (
        <Accordion
          key={index}
          allowMultiple
          borderWidth="0px"
          id={route.name}
          variant="unstyled"
          borderColor={'transparent'}
          defaultIndex={accordianIndex === route.name ? [0] : []}
          onChange={() => handleAccordionChange(route.name)}
        >
          <AccordionItem>
            <AccordionButton px={2} pl={7}>
              <Box
                color={
                  activeRoute(route.path.toLowerCase()) ? activeIcon : textColor
                }
                mt={1}
              >
                {route.icon}
              </Box>

              <Box flex="1" textAlign="left" ml={5}>
                <Text
                  color={
                    activeRoute(route.path.toLowerCase())
                      ? activeColor
                      : inactiveColor
                  }
                  fontWeight={
                    activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
                  }
                >
                  {route.name}
                  {'  '} {hasLength && `(${length})`}
                </Text>
              </Box>
              <AccordionIcon
                color={
                  activeRoute(route.path.toLowerCase()) ? activeIcon : textColor
                }
              />
            </AccordionButton>

            <AccordionPanel pb={4}>
              {route.subRoutes?.map((subroute, idx) => (
                <RouteComp route={subroute} index={idx} key={idx} />
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    }

    const isHome =
      route.path === '/agents/home' ||
      route.path === '/agents/invite' ||
      route.path === '/agents/faq' ||
      route.path === '/agents/incentives' ||
      route.path === '/agents/contact_us' ||
      route.path === '/agents/initiate_offer' ||
      route.path === '/agents/marketing' ||
      route.path === '/agents/landlord/marketing' ||
      route.path === '/agents/properties';
    const length = getCurrentRouteCount(route.path);

    let notSelectedColor = textColor;

    if (route.path === '/agents/incentives') {
      activeColor = useColorModeValue('red', 'red');
      notSelectedColor = 'red';
    } else {
      activeColor = useColorModeValue('#FFFFFF', 'white');
      notSelectedColor = textColor;
    }

    const routeColor = activeRoute(route.path.toLowerCase())
      ? activeColor
      : notSelectedColor;

    return (
      <NavLink key={index} to={route.path}>
        {route.icon ? (
          <Box>
            <HStack
              spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
              py="5px"
              ps="10px"
              // background={
              //   activeRoute(route.path.toLowerCase())
              //     ? 'rgba(0,0,0,0.2)'
              //     : 'transparent'
              // }
              // borderRadius={6}
            >
              <Flex w="100%" alignItems="center" justifyContent="center">
                <Box color={routeColor} me="14px">
                  {route.icon}
                </Box>
                <Text
                  me="auto"
                  color={routeColor}
                  fontWeight={
                    activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
                  }
                  fontSize="md"
                >
                  {route.name}
                  {'  '}
                  {!isHome && `(${length})`}
                </Text>
              </Flex>
              <Box
                h="36px"
                w="4px"
                bg={
                  activeRoute(route.path.toLowerCase())
                    ? brandColor
                    : 'transparent'
                }
                borderRadius="5px"
              />
            </HStack>
          </Box>
        ) : (
          <Box>
            <HStack
              spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
              py="5px"
              ps="10px"
            >
              <Text
                me="auto"
                color={
                  activeRoute(route.path.toLowerCase())
                    ? activeColor
                    : inactiveColor
                }
                fontWeight={
                  activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
                }
              >
                {route.name}
              </Text>
              <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
            </HStack>
          </Box>
        )}
      </NavLink>
    );
  };

  const createLinks = routes => {
    return routes.map((route, index) => {
      if (route.subRoutes?.length > 0) {
        const length = getCurrentRouteCount(route.path);
        const hasLength = !route.subRoutes.length > 0;

        return (
          <Accordion
            key={index}
            allowMultiple
            borderWidth="0px"
            id={route.name}
            variant="unstyled"
            borderColor={'transparent'}
            defaultIndex={accordianIndex === route.name ? [0] : []}
            onChange={() => handleAccordionChange(route.name)}
          >
            <AccordionItem>
              <AccordionButton px={2}>
                <Box
                  color={
                    activeRoute(route.path.toLowerCase())
                      ? activeIcon
                      : textColor
                  }
                  mt={1}
                >
                  {route.icon}
                </Box>

                <Box flex="1" textAlign="left" ml={5}>
                  <Text
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'
                    }
                  >
                    {route.name}
                    {'  '} {hasLength && `(${length})`}
                  </Text>
                </Box>
                <AccordionIcon
                  color={
                    activeRoute(route.path.toLowerCase())
                      ? activeIcon
                      : textColor
                  }
                />
              </AccordionButton>

              <AccordionPanel pb={4}>
                {route.subRoutes?.map((route, index) => (
                  <RouteComp route={route} index={index} key={index} />
                ))}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      } else {
        return <RouteComp route={route} index={index} key={index} />;
      }
    });
  };

  return createLinks(routes);
};

export default SidebarLinks;
