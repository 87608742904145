import { CheckIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAgentAuth } from '../../../context/AgentContext';
import { APPLICATION_STATUS } from '../../../utils/constants';
import AgentDashboardSidebar from '../../Sidebar';
import {
  Box,
  Text,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  Button,
  Spinner,
  useToast,
} from '@chakra-ui/react';

const ApplicationDetails = () => {
  const [applicationDetails, setApplicationDetails] = useState();

  const [loading, setLoading] = useState(false);
  const [interactions, setInteractions] = useState([]);
  const [value, setValue] = useState('');

  const {
    getSingleApplicationDetails,
    saveAgentInteraction,
    getInteractionsByClient,
    getStatus,
  } = useAgentAuth();

  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const getDetails = async () => {
    try {
      setLoading(true);

      let searchParams = new URLSearchParams(location.search);
      let email = searchParams.get('client_email');

      if (!email) {
        navigate('/agents/home');
        return;
      }

      const response = await getSingleApplicationDetails(email);

      setApplicationDetails(response);
      setLoading(false);

      await getInteractionsData(email);
    } catch (err) {
      setLoading(false);

      toast({
        title: 'Request Failed',
        description:
          'Unable to fetch application details at the moment. Please try again!',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const getInteractionsData = async email => {
    const interactions = await getInteractionsByClient(email);
    setInteractions(interactions);
    setValue('');
  };

  useEffect(() => {
    getDetails();
  }, []);

  const STATUS = applicationDetails?.status;

  const statuses = [
    'Invited',
    'Started',
    'Prequalified',
    APPLICATION_STATUS.CONDITIONALLY_APROVED,
    APPLICATION_STATUS.APPROVED,
    APPLICATION_STATUS.OFFER_EXTENDED,
    APPLICATION_STATUS.OFFER_ACCEPTED,
    APPLICATION_STATUS.INSPECTION_COMPLETE,
    APPLICATION_STATUS.PRECLOSING_COMPLETE,
    APPLICATION_STATUS.OFFER_CLOSED,
  ];

  const STATUS_INDEX = statuses.findIndex(status => status === STATUS);

  const ISQUALIFIED = applicationDetails?.user?.qualifiedAmount > 0;

  const QUALIFIED_AMOUNT =
    applicationDetails?.user?.qualifiedAmount > 0
      ? applicationDetails?.user?.qualifiedAmount?.toLocaleString()
      : applicationDetails?.application?.stepEight
      ? applicationDetails?.application?.stepEight[0]?.answer
      : 'Unknown';

  return (
    <AgentDashboardSidebar showHeader={false} hasPadding={false}>
      <Flex
        flex={1}
        position="relative"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Box
          flex={1}
          background="white"
          height={'100vh'}
          px={10}
          py={10}
          // position="sticky"
          position={{ base: 'relative', md: 'sticky' }}
          top={0}
        >
          <Text color={'gray.500'} fontSize="sm" fontWeight={'semibold'}>
            Client
          </Text>
          <Text fontWeight={'semibold'} fontSize="2xl">
            {applicationDetails?.name}
          </Text>

          <LeftBarSectionInfo
            title="Status"
            details={applicationDetails?.currentStatus}
          />
          <LeftBarSectionInfo
            title="Primary phone"
            details={applicationDetails?.phone}
            isLink
          />
          <LeftBarSectionInfo
            title="Primary email"
            details={applicationDetails?.email}
            isLink
          />

          <LeftBarSectionInfo
            title={ISQUALIFIED ? 'Home Buying Budget' : 'Prequalified Amount'}
            details={QUALIFIED_AMOUNT}
          />

          <LeftBarSectionInfo
            title="Last login"
            details={applicationDetails?.lastLogin}
          />
        </Box>
        <Box flex={3}>
          <Tabs size="md" isFitted>
            <TabList>
              <Tab mt={3}>Progress</Tab>
              <Tab mt={3}>Interactions</Tab>
            </TabList>

            <TabPanels>
              <TabPanel p={4}>
                {!loading ? (
                  <>
                    {statuses.map((status, index) => {
                      let name = status;

                      if (index > 2) {
                        name = getStatus(status);
                      }
                      return (
                        <ProgressCard
                          key={status}
                          title={name}
                          isDone={index <= STATUS_INDEX}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <Flex
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                      minHeight="85vh"
                    >
                      <Spinner />
                    </Flex>
                  </>
                )}
              </TabPanel>

              <TabPanel>
                <Flex background={'white'} flex={1} p={4}>
                  <Input
                    placeholder="Add Interaction"
                    onChange={e => setValue(e.target.value)}
                    mr={3}
                    value={value}
                  />
                  <Button
                    onClick={() => {
                      saveAgentInteraction(applicationDetails?.email, value);
                      getInteractionsData(applicationDetails?.email);
                    }}
                  >
                    Add
                  </Button>
                </Flex>

                {interactions.map((item, index) => {
                  return (
                    <InteractionsCard
                      title={item.description}
                      date={item.createdAt}
                      key={index.toString()}
                    />
                  );
                })}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </AgentDashboardSidebar>
  );
};

const LeftBarSectionInfo = ({ title, details, isLink }) => {
  return (
    <Box mt={4}>
      <Text color={'gray.500'} fontSize="sm" fontWeight={'semibold'}>
        {title}
      </Text>
      <Text fontWeight={'semibold'} color={isLink ? 'blue.500' : 'black'}>
        {details}
      </Text>
    </Box>
  );
};

const ProgressCard = ({ title, isDone }) => {
  return (
    <Box background={'white'} mt={5} py={6} px={10} borderRadius={'md'}>
      <Flex alignItems={'center'} justifyContent="space-between">
        <Text
          fontWeight={'bold'}
          fontSize="lg"
          color={isDone ? 'green.500' : 'black'}
        >
          {title}
        </Text>
        {isDone && <CheckIcon color="green.500" />}
      </Flex>
    </Box>
  );
};

const InteractionsCard = ({ title, date }) => {
  return (
    <Box background={'white'} mt={5} py={6} px={10} borderRadius={'md'}>
      <Text fontSize={'sm'}>{moment(date).format('ll')}</Text>

      <Text fontWeight="semibold" color={'black'}>
        {title}
      </Text>
    </Box>
  );
};

export default ApplicationDetails;
