import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { createSwapy, utils, SlotItemMapArray, Swapy } from 'swapy';
import heic2any from 'heic2any';

// context
import { useLandlord } from '../../../../../context/LandlordContext';

// chakra
import {
  Box,
  Button,
  Card,
  CardBody,
  Text,
  Grid,
  GridItem,
  Stack,
  StackDivider,
  Divider,
  List,
  ListItem,
  UnorderedList,
  OrderedList,
  Container,
  Flex,
  Image,
  Link,
  Spacer,
  Textarea,
  Heading,
  IconButton,
  Icon,
  SimpleGrid,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { FiTrash2, FiPlus } from 'react-icons/fi';

const AddPropertyStepSix = ({ setCurrentStep, handleNext, isLoading }) => {
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();

  const { currentApplicationForm } = useLandlord();

  const [propertyImages, setPropertyImages] = useState([]);
  const [slotItemMap, setSlotItemMap] = useState(
    utils.initSlotItemMap(propertyImages, 'id')
  );

  const [alertDialog, setAlertDialog] = useState('');
  const [imageConvertionLoading, setImageConvertionLoading] = useState(false);
  const [imagesLoading, setImagesLoading] = useState(true);

  const slottedItems = useMemo(
    () => utils.toSlottedItems(propertyImages, 'id', slotItemMap),
    [propertyImages, slotItemMap]
  );

  const swapyRef = useRef(null);
  const containerRef = useRef(null);
  const propertyImagesRef = useRef(null);
  const cancelRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      setImagesLoading(true);

      if (currentApplicationForm?.file?.propertyImages) {
        const sortedImages = [...currentApplicationForm.file.propertyImages]
          .map((image, index) => ({
            ...image,
            id: `img_${Math.random().toString(36).substr(2, 9)}`,
          }))
          .sort((a, b) => {
            // Handle cloud files
            if (a.fileLocation === 'cloud' && b.fileLocation === 'cloud') {
              const orderA =
                parseInt(a.data.fileMetadata.customMetadata.order) || 0;
              const orderB =
                parseInt(b.data.fileMetadata.customMetadata.order) || 0;
              return orderA - orderB;
            }
            // Keep local files at the end in their current order
            if (a.fileLocation === 'local' && b.fileLocation === 'local') {
              return 0;
            }
            // Always put cloud files before local files
            return a.fileLocation === 'cloud' ? -1 : 1;
          });

        setPropertyImages(sortedImages);
      } else {
        setPropertyImages([]);
      }

      setImagesLoading(false);
    };

    fetchData().catch(console.error);
  }, [currentApplicationForm]);

  useEffect(
    () =>
      utils.dynamicSwapy(
        swapyRef.current,
        propertyImages,
        'id',
        slotItemMap,
        setSlotItemMap
      ),
    [propertyImages]
  );

  useEffect(() => {
    swapyRef.current = createSwapy(containerRef.current, {
      manualSwap: true,
    });

    swapyRef.current.onSwap(event => {
      setSlotItemMap(event.newSlotItemMap.asArray);
    });

    return () => {
      swapyRef.current?.destroy();
    };
  }, []);

  const isValidFileSize = (file, minSizeKB = 5, maxSizeMB = 10) => {
    const fileSizeKB = file.size / 1024;
    const fileSizeMB = fileSizeKB / 1024;
    return fileSizeKB >= minSizeKB && fileSizeMB <= maxSizeMB;
  };

  const isDuplicateFile = (existingFiles, newFile) => {
    return existingFiles.some(file => {
      if (file.fileLocation === 'cloud') {
        return (
          file.data.fileMetadata.name === newFile.name &&
          file.data.fileMetadata.size === newFile.size
        );
      } else {
        return file.name === newFile.name && file.size === newFile.size;
      }
    });
  };

  const convertHeicToJpeg = useCallback(async file => {
    if (
      file.type === 'image/heic' ||
      file.name.toLowerCase().endsWith('.heic')
    ) {
      try {
        const jpegBlob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.8,
        });
        return new File([jpegBlob], file.name.replace(/\.heic$/i, '.jpg'), {
          type: 'image/jpeg',
        });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
        return file;
      }
    }
    return file;
  }, []);

  const handleAddMoreFiles = async (setFieldValue, existingFiles, newFiles) => {
    try {
      const validAndUniqueFiles = [];
      let erroredFilesList = [];

      // First check all files for size before proceeding
      const oversizedFiles = Array.from(newFiles).filter(
        file => !isValidFileSize(file)
      );
      if (oversizedFiles.length > 0) {
        const fileList = oversizedFiles
          .map(
            file => `${file.name} (${(file.size / (1024 * 1024)).toFixed(2)}MB)`
          )
          .join('\n');
        setAlertDialog(
          `The following files exceed the maximum size limit of 10MB:\n\n${fileList}\n\nPlease compress these images before uploading.`
        );
        alertOnOpen();
        return existingFiles;
      }

      if (existingFiles.length + newFiles.length > 35) {
        setAlertDialog(`You can only upload a maximum of 35 property images`);
        alertOnOpen();
        return existingFiles;
      }

      for (let i = 0; i < newFiles.length; i++) {
        let newFile = newFiles[i];

        try {
          newFile = await convertHeicToJpeg(newFile);

          // Double-check size after HEIC conversion
          if (!isValidFileSize(newFile)) {
            erroredFilesList.push(
              `${newFile.name} (${(newFile.size / (1024 * 1024)).toFixed(
                2
              )}MB - exceeds 10MB limit)`
            );
            continue;
          }

          if (isDuplicateFile(existingFiles, newFile)) {
            erroredFilesList.push(`${newFile.name} (duplicate)`);
            continue;
          }

          validAndUniqueFiles.push({
            fileLocation: 'local',
            data: newFile, // Store the entire File object
            name: newFile.name,
            size: newFile.size,
            type: newFile.type,
            id: `img_${Math.random().toString(36).substr(2, 9)}`,
          });
        } catch (err) {
          console.error(`Error processing ${newFile.name}:`, err);
          erroredFilesList.push(`${newFile.name} (${err.message})`);
          continue;
        }
      }

      if (erroredFilesList.length > 0) {
        const errorMessage =
          erroredFilesList.length === newFiles.length
            ? 'None of the images could be uploaded. Please try again with different images.'
            : `Some images could not be uploaded:\n\n${erroredFilesList.join(
                '\n'
              )}\n\nOther images were uploaded successfully.`;

        setAlertDialog(errorMessage);
        alertOnOpen();
      }

      if (validAndUniqueFiles.length > 0) {
        return [...existingFiles, ...validAndUniqueFiles];
      }

      return existingFiles;
    } catch (err) {
      console.error('Error in handleAddMoreFiles:', err);
      setAlertDialog(
        'An unexpected error occurred while uploading images. Please try again.'
      );
      alertOnOpen();
      return existingFiles;
    }
  };

  const handleDeleteFile = (existingFiles, itemId) => {
    const updatedFiles = [...existingFiles];
    updatedFiles.splice(
      updatedFiles.findIndex(file => file.id === itemId),
      1
    );

    return updatedFiles;
  };

  const handleDescriptionChange = (existingFiles, index, description) => {
    try {
      const updatedFiles = [...existingFiles];
      if (updatedFiles[index].fileLocation === 'cloud') {
        if (updatedFiles[index]?.data?.fileMetadata?.customMetadata) {
          updatedFiles[
            index
          ].data.fileMetadata.customMetadata.propertyDescription = description;
        }
      } else {
        if (updatedFiles[index]?.data?.description) {
          updatedFiles[index].data.description = description;
        }
      }

      return updatedFiles;
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async () => {
    console.log('Running handleSubmit()');
    try {
      let itemOrder = [];
      for (let i = 0; i < slotItemMap.length; i++) {
        itemOrder.push(slotItemMap[i].item);
      }

      let orderedPropertyImages = [];
      for (let i = 0; i < itemOrder.length; i++) {
        const currentItem = itemOrder[i];
        for (let j = 0; j < propertyImages.length; j++) {
          const currentImage = propertyImages[j];
          if (currentImage.id === currentItem) {
            orderedPropertyImages.push({
              data: currentImage.data,
              fileLocation: currentImage.fileLocation,
            });
            break;
          }
        }
      }

      await handleNext({
        propertyImages: orderedPropertyImages,
      });
    } catch (err) {
      console.log('Error in handleSubmit():', err);
    }
  };

  return (
    <Fragment>
      <Stack spacing="5">
        <Card bg="white" size="lg" ref={containerRef}>
          <CardBody>
            <Heading as="h4" size="md" mb="5">
              Add Property Photos
            </Heading>

            <input
              type="file"
              ref={propertyImagesRef}
              id="propertyImages"
              name="propertyImages"
              multiple={true}
              accept="image/jpeg,image/gif,image/png,image/x-eps,image/jpg,image/*,.heic"
              style={{ display: 'none' }}
              onChange={async e => {
                try {
                  setImageConvertionLoading(true);

                  const updatedImages = await handleAddMoreFiles(
                    setPropertyImages,
                    propertyImages,
                    e.target.files
                  );
                  await handleNext(
                    {
                      propertyImages: updatedImages,
                    },
                    false
                  );

                  setImageConvertionLoading(false);
                } catch (err) {
                  setAlertDialog(err);
                  alertOnOpen();
                  setImageConvertionLoading(false);
                }
              }}
            />

            {propertyImages.length === 0 ? (
              <Flex align="center">
                <Button
                  as="label"
                  htmlFor="propertyImages"
                  leftIcon={<Icon as={FiPlus} />}
                  isDisabled={isLoading || imagesLoading}
                  isLoading={imageConvertionLoading}
                  w="100%"
                >
                  <Text>Upload Property Images</Text>
                </Button>
              </Flex>
            ) : (
              <Box>
                <Flex align="center" mb="5">
                  <Button
                    as="label"
                    htmlFor="propertyImages"
                    leftIcon={<Icon as={FiPlus} />}
                    isDisabled={isLoading || imagesLoading}
                    isLoading={imageConvertionLoading}
                    w="100%"
                  >
                    <Text>Add More Property Images</Text>
                  </Button>
                </Flex>

                <Flex
                  className="users"
                  direction="column"
                  gap="3"
                  bg="gray.100"
                  p="4"
                  rounded="md"
                >
                  {slottedItems.map(({ slotId, itemId, item }) => (
                    <Box className="slot" key={slotId} data-swapy-slot={slotId}>
                      {item && (
                        <Box
                          className="item"
                          data-swapy-item={itemId}
                          key={itemId}
                          cursor="move"
                        >
                          <Flex
                            direction={{ base: 'column', md: 'row' }}
                            boxShadow="md"
                            borderRadius="5px"
                            w="100%"
                            backgroundColor="white"
                          >
                            <Image
                              objectFit="cover"
                              src={(() => {
                                if (item?.fileLocation === 'cloud') {
                                  return item?.data?.fileUrl;
                                }
                                if (
                                  item?.fileLocation === 'local' &&
                                  item?.data
                                ) {
                                  try {
                                    return window.URL.createObjectURL(
                                      item.data
                                    );
                                  } catch (err) {
                                    console.error(
                                      'Error creating object URL:',
                                      err
                                    );
                                    return '';
                                  }
                                }
                                return '';
                              })()}
                              onLoad={e => {
                                if (
                                  item?.fileLocation === 'local' &&
                                  e.target.src &&
                                  e.target.src.startsWith('blob:')
                                ) {
                                  window.URL.revokeObjectURL(e.target.src);
                                }
                              }}
                              borderRadius="5px 0 0 5px"
                              maxW={{ base: '100%', md: '200px' }}
                              fallbackSrc="https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                            />
                            <Flex w="100%" p="4" flexDirection="column">
                              <Text fontWeight="600" mr="2">
                                {item?.fileLocation === 'cloud'
                                  ? item?.data?.fileMetadata?.name.length > 30
                                    ? item?.data?.fileMetadata?.name.slice(
                                        0,
                                        30
                                      ) + '..'
                                    : item?.data?.fileMetadata?.name
                                  : item?.data?.name.length > 30
                                  ? item?.data?.name.slice(0, 30) + '..'
                                  : item?.data?.name}
                              </Text>

                              <Textarea
                                placeholder="Add a description (optional)"
                                mt="3"
                                mb="3"
                                value={
                                  item?.fileLocation === 'cloud'
                                    ? item?.data?.fileMetadata?.customMetadata
                                        ?.propertyDescription
                                    : item?.data?.description
                                }
                                onChange={e => {
                                  const arrayIndex = propertyImages.findIndex(
                                    image => image.id === itemId
                                  );
                                  const updatedImages = [...propertyImages];

                                  if (item?.fileLocation === 'cloud') {
                                    updatedImages[
                                      arrayIndex
                                    ].data.fileMetadata.customMetadata.propertyDescription =
                                      e.target.value;
                                  } else {
                                    updatedImages[arrayIndex].data.description =
                                      e.target.value;
                                  }
                                  setPropertyImages(updatedImages);
                                }}
                                onBlur={async e => {
                                  try {
                                    setImageConvertionLoading(true);

                                    const arrayIndex = propertyImages.findIndex(
                                      image => image.id === itemId
                                    );

                                    const updatedFiles =
                                      handleDescriptionChange(
                                        propertyImages,
                                        arrayIndex,
                                        e.target.value
                                      );

                                    let itemOrder = [];
                                    for (
                                      let i = 0;
                                      i < slotItemMap.length;
                                      i++
                                    ) {
                                      itemOrder.push(slotItemMap[i].item);
                                    }

                                    let orderedPropertyImages = [];
                                    for (let i = 0; i < itemOrder.length; i++) {
                                      const currentItem = itemOrder[i];
                                      for (
                                        let j = 0;
                                        j < updatedFiles.length;
                                        j++
                                      ) {
                                        const currentImage = updatedFiles[j];
                                        if (currentImage.id === currentItem) {
                                          orderedPropertyImages.push({
                                            data: currentImage.data,
                                            fileLocation:
                                              currentImage.fileLocation,
                                          });
                                          break;
                                        }
                                      }
                                    }

                                    await handleNext(
                                      {
                                        propertyImages: orderedPropertyImages,
                                      },
                                      false
                                    );
                                    setImageConvertionLoading(false);
                                  } catch (err) {
                                    console.log(err);
                                    setImageConvertionLoading(false);
                                  }
                                }}
                                isDisabled={
                                  isLoading ||
                                  imageConvertionLoading ||
                                  imagesLoading
                                }
                                maxLength={255}
                              />
                              {item?.fileLocation === 'cloud'
                                ? item?.data?.fileMetadata?.customMetadata
                                    ?.propertyDescription &&
                                  item?.data?.fileMetadata?.customMetadata
                                    ?.propertyDescription.length === 255 && (
                                    <Text fontSize="sm" color="red.500">
                                      Max 255 characters
                                    </Text>
                                  )
                                : item?.data?.description &&
                                  item?.data?.description.length === 255 && (
                                    <Text fontSize="sm" color="red.500">
                                      Max 255 characters
                                    </Text>
                                  )}

                              <Spacer />

                              <Flex>
                                <Spacer />
                                <IconButton
                                  colorScheme="red"
                                  aria-label="delete file"
                                  icon={<Icon as={FiTrash2} />}
                                  onClick={async () => {
                                    try {
                                      setImageConvertionLoading(true);
                                      const updatedImages = handleDeleteFile(
                                        propertyImages,
                                        item.id
                                      );

                                      let itemOrder = [];
                                      for (
                                        let i = 0;
                                        i < slotItemMap.length;
                                        i++
                                      ) {
                                        itemOrder.push(slotItemMap[i].item);
                                      }

                                      let orderedPropertyImages = [];
                                      for (
                                        let i = 0;
                                        i < itemOrder.length;
                                        i++
                                      ) {
                                        const currentItem = itemOrder[i];
                                        for (
                                          let j = 0;
                                          j < updatedImages.length;
                                          j++
                                        ) {
                                          const currentImage = updatedImages[j];
                                          if (currentImage.id === currentItem) {
                                            orderedPropertyImages.push({
                                              data: currentImage.data,
                                              fileLocation:
                                                currentImage.fileLocation,
                                            });
                                            break;
                                          }
                                        }
                                      }

                                      await handleNext(
                                        {
                                          propertyImages: orderedPropertyImages,
                                        },
                                        false
                                      );
                                      setImageConvertionLoading(false);
                                    } catch (err) {
                                      console.log(err);
                                      setImageConvertionLoading(false);
                                    }
                                  }}
                                  isDisabled={
                                    isLoading ||
                                    imageConvertionLoading ||
                                    imagesLoading
                                  }
                                />
                              </Flex>
                            </Flex>
                          </Flex>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Flex>
              </Box>
            )}

            <Flex align="center" mt="5" direction="column">
              <Stack>
                <Box>
                  <b>
                    The first photo will be used as the main photo for your
                    home.
                  </b>
                </Box>
                <Box>
                  Add clear and nicely captured photos for all the bedrooms,
                  bathrooms, Kitchen, Garage, frontyard/ backyard with
                  descriptions. Please add some nice photos of the home. The
                  homes with nice pictures get a customer <b>FIVE</b> times
                  faster.
                </Box>
              </Stack>
            </Flex>
          </CardBody>
        </Card>

        <Stack justify="right" direction="row" spacing="3">
          <Button
            isDisabled={isLoading || imagesLoading}
            onClick={() => {
              setCurrentStep(5);
            }}
          >
            Prev
          </Button>
          <Button
            colorScheme="green"
            isLoading={isLoading || imagesLoading}
            onClick={async () => {
              if (propertyImages.length === 0) {
                setAlertDialog(
                  'Please upload at least one property image. Listings with appealing pictures attract customers 5X faster!'
                );

                alertOnOpen();
              } else {
                await handleSubmit();
              }
            }}
          >
            Next
          </Button>
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={alertIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Oops!
            </AlertDialogHeader>

            <AlertDialogBody>{alertDialog}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="gray" onClick={alertOnClose} ml={3}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Fragment>
  );
};

export default AddPropertyStepSix;
