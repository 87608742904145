import React, { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// utils
import SEO from '../utils/SEO';

// chakra
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Hide,
  Image,
  Link,
  Show,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';

const Accounts = () => {
  const navigate = useNavigate();

  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let email = searchParams.get('email');
  let isInvited = searchParams.get('invited');

  return (
    <Fragment>
      <SEO
        title={`Accounts`}
        description={`Accounts page for Utopia Homes. Create an account to get started.`}
        name={`Utopia Homes`}
        type={`application page`}
      />
      <h1 className="sr-only" style={{ display: 'none' }}>
        Accounts - Utopia Homes
      </h1>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        h="100vh"
      >
        <GridItem colSpan={1} px="6">
          <Flex w="100%" h="100vh" direction="column">
            <Box py="8">
              <Link href="https://www.utopiahomes.us/">
                <Image
                  objectFit="cover"
                  w="150px"
                  src="https://i.imgur.com/avkbaJm.png"
                  alt="Utopia Logo"
                />
              </Link>
            </Box>
            <Show above="md">
              <Spacer />
            </Show>
            <Flex align="center" justify="center">
              <Stack align="center" spacing="3">
                <Heading textAlign="center">
                  Get started with Utopia Homes
                </Heading>
                {/* <Show below="md">
                  <Card bg="gray.100" maxW="350px" boxShadow="lg">
                    <CardBody>
                      <Heading size="sm" mb="5">
                        Requirements
                      </Heading>
                      <Stack spacing="4">
                        <Flex align="center">
                          <CheckCircleIcon
                            boxSize={4}
                            mr="3"
                            color="green.500"
                          />
                          <Text fontWeight="500" fontSize="sm">
                            Minimum FICO score of 550
                          </Text>
                        </Flex>
                        <Flex align="center">
                          <CheckCircleIcon
                            boxSize={4}
                            mr="3"
                            color="green.500"
                          />
                          <Text fontWeight="500" fontSize="sm">
                            $3,500 Minimum Monthly Household Income
                          </Text>
                        </Flex>
                        <Flex align="center">
                          <CheckCircleIcon
                            boxSize={4}
                            mr="3"
                            color="green.500"
                          />
                          <Text fontWeight="500" fontSize="sm">
                            Less than 50% Debt to Income Ratio (including Rent)
                          </Text>
                        </Flex>
                        <Flex align="center">
                          <CheckCircleIcon
                            boxSize={4}
                            mr="3"
                            color="green.500"
                          />
                          <Text fontWeight="500" fontSize="sm">
                            12 Months of timely rent payments
                          </Text>
                        </Flex>
                        <Flex align="center">
                          <CheckCircleIcon
                            boxSize={4}
                            mr="3"
                            color="green.500"
                          />
                          <Text fontWeight="500" fontSize="sm">
                            Upfront cash of 1-3% of the Home Purchase Price
                          </Text>
                        </Flex>
                      </Stack>
                    </CardBody>
                  </Card>
                </Show> */}
                <Stack spacing="4" w="100%">
                  <Button
                    size="lg"
                    w="100%"
                    colorScheme="blue"
                    onClick={() => {
                      if (isInvited) {
                        navigate(
                          `/signup/homebuyer?invited=true&email=${email}`
                        );
                      } else {
                        navigate('/signup/homebuyer');
                      }
                    }}
                  >
                    Homebuyer
                  </Button>
                  <Button
                    size="lg"
                    w="100%"
                    colorScheme="blue"
                    onClick={() => {
                      navigate('/signup/landlord');
                    }}
                  >
                    Landlord
                  </Button>
                  <Button
                    size="lg"
                    w="100%"
                    colorScheme="blue"
                    onClick={() => {
                      navigate('/signup/agent');
                    }}
                  >
                    Agent
                  </Button>
                </Stack>
                <Flex align="center" justify="center">
                  <Text fontSize="md" as="b" mr="3">
                    Already have an account?
                  </Text>
                  <Link
                    fontSize="md"
                    as="b"
                    color="blue.500"
                    onClick={() => {
                      navigate('/login');
                    }}
                  >
                    Log In
                  </Link>
                </Flex>
              </Stack>
            </Flex>
            <Spacer />
          </Flex>
        </GridItem>
        <Hide below="md">
          <GridItem colSpan={1}>
            <Image
              src="https://firebasestorage.googleapis.com/v0/b/utopia-prod-8c73d.appspot.com/o/website_images%2F9uEAC7t%20(1).jpg?alt=media&token=b0014916-7f0d-4412-846e-5c6856c12d18"
              objectFit="cover"
              h="100vh"
            />
          </GridItem>
        </Hide>
      </Grid>
    </Fragment>
  );
};

export default Accounts;
