import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// context
import { useLandlord } from '../../../context/LandlordContext';

// components
import SidebarWithHeader from '../../../components/SidebarWithHeader';
import AgentDashboardSidebar from '../../../AgentDashboard/Sidebar';
import TextRichView from '../../../components/TextRich/TextRichView';

// functions
import {
  currencyFormatter,
  numberWithCommas,
} from '../../../utils/generalFunctions';

// chakra
import {
  Container,
  Flex,
  Spacer,
  Stack,
  Text,
  Box,
  StackDivider,
  Image,
  Button,
  Link,
  Skeleton,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

const LandlordPropertyDetails = () => {
  const navigate = useNavigate();
  const { id, userType } = useParams();
  const { getPropertyById_Full, getLandlordApplicationById } = useLandlord();

  const [property, setProperty] = useState(null);
  const [landlordApplication, setLandlordApplication] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        if (id) {
          const propertyData = await getPropertyById_Full(id, true);
          const userApplication = await getLandlordApplicationById(
            propertyData?.data?.userId
          );

          if (userApplication) {
            setLandlordApplication({
              firstName: userApplication?.stepOne[0]?.answer,
              lastName: userApplication?.stepOne[1]?.answer,
              phoneNumber: userApplication?.stepOne[2]?.answer,
              email: userApplication?.email,
            });
          }

          setProperty(propertyData);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const PropertyContent = () => {
    return (
      <>
        {userType === 'landlord' && (
          <Button
            leftIcon={<ArrowBackIcon />}
            size="xs"
            variant="link"
            onClick={() => {
              navigate('/landlord/home');
            }}
          >
            Back
          </Button>
        )}
        <Stack direction={'column'} gap="7" mb="5">
          <Text fontSize="2xl" fontWeight="600">
            My Property:
          </Text>

          {isLoading ? (
            <>
              <Stack gap="7">
                <Skeleton borderRadius="md">
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                </Skeleton>
                <Skeleton borderRadius="md">
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                </Skeleton>
                <Skeleton borderRadius="md">
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                </Skeleton>
              </Stack>
            </>
          ) : (
            <>
              {landlordApplication && (
                <Stack
                  p="4"
                  bg="gray.300"
                  borderRadius="md"
                  direction={'column'}
                  divider={<StackDivider borderColor="gray.500" />}
                >
                  <Text fontSize="lg" fontWeight="600">
                    Landlord Details:
                  </Text>

                  <Flex align={'center'} gap="5">
                    <Text>Landlord First Name:</Text>
                    <Text fontWeight="600">
                      {landlordApplication?.firstName}
                    </Text>
                  </Flex>

                  <Flex align={'center'} gap="5">
                    <Text>Landlord Last Name:</Text>
                    <Text fontWeight="600">
                      {landlordApplication?.lastName}
                    </Text>
                  </Flex>

                  <Flex align={'center'} gap="5">
                    <Text>Landlord Phone Number:</Text>
                    <Text fontWeight="600">
                      {landlordApplication?.phoneNumber}
                    </Text>
                  </Flex>

                  <Flex align={'center'} gap="5">
                    <Text>Landlord Email:</Text>
                    <Text fontWeight="600">{landlordApplication?.email}</Text>
                  </Flex>
                </Stack>
              )}

              <Stack
                p="4"
                bg="gray.300"
                borderRadius="md"
                direction={'column'}
                divider={<StackDivider borderColor="gray.500" />}
              >
                <Text fontSize="lg" fontWeight="600">
                  Step One:
                </Text>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepOne[0]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepOne[0]?.answer
                      ? property?.data?.stepOne[0]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepOne[1]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepOne[1]?.answer
                      ? property?.data?.stepOne[1]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepOne[2]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepOne[2]?.answer
                      ? property?.data?.stepOne[2]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepOne[3]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepOne[3]?.answer
                      ? currencyFormatter(property?.data?.stepOne[3]?.answer)
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepOne[4]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepOne[4]?.answer
                      ? numberWithCommas(property?.data?.stepOne[4]?.answer)
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepOne[5]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepOne[5]?.answer
                      ? property?.data?.stepOne[5]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepOne[6]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepOne[6]?.answer
                      ? numberWithCommas(property?.data?.stepOne[6]?.answer)
                      : 'N/A'}
                  </Text>
                </Flex>
              </Stack>

              <Stack
                p="4"
                bg="gray.300"
                borderRadius="md"
                direction={'column'}
                divider={<StackDivider borderColor="gray.500" />}
              >
                <Text fontSize="lg" fontWeight="600">
                  Step Two:
                </Text>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepTwo[0]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepTwo[0]?.answer
                      ? property?.data?.stepTwo[0]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepTwo[1]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepTwo[1]?.answer
                      ? property?.data?.stepTwo[1]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepTwo[2]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepTwo[2]?.answer
                      ? property?.data?.stepTwo[2]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  align={{ base: 'flex-start', md: 'center' }}
                  gap="5"
                >
                  <Text>{property?.data?.stepTwo[3]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepTwo[3]?.answer
                      ? property?.data?.stepTwo[3]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>
              </Stack>

              <Stack
                p="4"
                bg="gray.300"
                borderRadius="md"
                direction={'column'}
                divider={<StackDivider borderColor="gray.500" />}
              >
                <Text fontSize="lg" fontWeight="600">
                  Step Three:
                </Text>

                <Flex align={'center'} gap="5">
                  <Text>{property?.data?.stepThree[0]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepThree[0]?.answer
                      ? currencyFormatter(property?.data?.stepThree[0]?.answer)
                      : 'N/A'}
                  </Text>
                </Flex>

                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  align={{ base: 'flex-start', md: 'center' }}
                  gap="5"
                >
                  <Text>{property?.data?.stepThree[1]?.question}:</Text>
                  <Text fontWeight="600">
                    {property?.data?.stepThree[1]?.answer
                      ? property?.data?.stepThree[1]?.answer
                      : 'N/A'}
                  </Text>
                </Flex>
              </Stack>

              <Stack
                p="4"
                bg="gray.300"
                borderRadius="md"
                direction={'column'}
                divider={<StackDivider borderColor="gray.500" />}
              >
                <Text fontSize="lg" fontWeight="600">
                  Step Four:
                </Text>

                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  align={{ base: 'flex-start', md: 'center' }}
                  gap="5"
                >
                  <Text>{property?.data?.stepFour[0]?.question}:</Text>
                  <TextRichView
                    value={
                      property?.data?.stepFour[0]?.answer
                        ? property?.data?.stepFour[0]?.answer
                        : 'N/A'
                    }
                  />
                </Flex>
              </Stack>

              <Stack
                p="4"
                bg="gray.300"
                borderRadius="md"
                direction={'column'}
                divider={<StackDivider borderColor="gray.500" />}
              >
                <Text fontSize="lg" fontWeight="600">
                  Step Five:
                </Text>

                <Flex align={'center'} gap="5">
                  <Text>CMA Sales Docs:</Text>
                  <Stack>
                    {property?.file?.salesCmaDocs.map((item, index) => (
                      <Link
                        color="blue.500"
                        href={item?.data?.fileUrl}
                        isExternal
                        fontWeight="600"
                      >
                        {item?.data?.fileMetadata?.name}
                      </Link>
                    ))}
                  </Stack>
                </Flex>

                <Flex align={'center'} gap="5">
                  <Text>Appraisal Report Docs:</Text>
                  <Stack>
                    {property?.file?.appraisalReportDocs.map((item, index) => (
                      <Link
                        color="blue.500"
                        href={item?.data?.fileUrl}
                        isExternal
                        fontWeight="600"
                      >
                        {item?.data?.fileMetadata?.name}
                      </Link>
                    ))}
                  </Stack>
                </Flex>
              </Stack>

              <Stack
                p="4"
                bg="gray.300"
                borderRadius="md"
                direction={'column'}
                divider={<StackDivider borderColor="gray.500" />}
              >
                <Text fontSize="lg" fontWeight="600">
                  Step Six - Property Images:
                </Text>

                <Flex align={'center'} gap="5">
                  <Stack spacing="3">
                    {property?.file?.propertyImages.map((item, index) => (
                      <Box>
                        <Image src={item?.data?.fileUrl} />
                        <Text fontWeight="600">
                          Image Description:{' '}
                          {
                            item?.data?.fileMetadata?.customMetadata
                              ?.propertyDescription
                          }
                        </Text>
                      </Box>
                    ))}
                  </Stack>
                </Flex>
              </Stack>

              {userType === 'agent' && (
                <Stack
                  p="4"
                  bg="gray.300"
                  borderRadius="md"
                  direction={'column'}
                  divider={<StackDivider borderColor="gray.500" />}
                >
                  <Text fontSize="lg" fontWeight="600">
                    Step Seven:
                  </Text>

                  <Flex align={'center'} gap="5">
                    <Text>{property?.data?.stepSeven[0]?.question}:</Text>
                    <Text fontWeight="600">
                      {property?.data?.stepSeven[0]?.answer
                        ? property?.data?.stepSeven[0]?.answer
                        : 'N/A'}
                    </Text>
                  </Flex>

                  <Flex align={'center'} gap="5">
                    <Text>{property?.data?.stepSeven[1]?.question}:</Text>
                    <Text fontWeight="600">
                      {property?.data?.stepSeven[1]?.answer
                        ? property?.data?.stepSeven[1]?.answer
                        : 'N/A'}
                    </Text>
                  </Flex>

                  <Flex align={'center'} gap="5">
                    <Text>{property?.data?.stepSeven[2]?.question}:</Text>
                    <Text fontWeight="600">
                      {property?.data?.stepSeven[2]?.answer
                        ? property?.data?.stepSeven[2]?.answer
                        : 'N/A'}
                    </Text>
                  </Flex>

                  <Flex align={'center'} gap="5">
                    <Text>{property?.data?.stepSeven[3]?.question}:</Text>
                    <Text fontWeight="600">
                      {property?.data?.stepSeven[3]?.answer
                        ? property?.data?.stepSeven[3]?.answer
                        : 'N/A'}
                    </Text>
                  </Flex>
                </Stack>
              )}

              <Stack
                p="4"
                bg="gray.300"
                borderRadius="md"
                direction={'column'}
                divider={<StackDivider borderColor="gray.500" />}
              >
                <Text fontSize="lg" fontWeight="600">
                  Other Details:
                </Text>

                <Flex align={'center'} gap="5">
                  <Text>Price Per Sq Ft:</Text>
                  <Text fontWeight="600">
                    {(() => {
                      const value = property?.data?.stepThree[0]?.answer;
                      const sqft = property?.data?.stepOne[4]?.answer;

                      if (!value || !sqft || parseFloat(sqft) === 0) {
                        return 'N/A';
                      }

                      const pricePerSqFt = parseFloat(value) / parseFloat(sqft);
                      return isFinite(pricePerSqFt)
                        ? `$ ${pricePerSqFt.toFixed(2)}`
                        : 'N/A';
                    })()}
                  </Text>
                </Flex>
              </Stack>
            </>
          )}
        </Stack>
      </>
    );
  };

  return (
    <Fragment>
      {userType === 'landlord' ? (
        <SidebarWithHeader userMode="home">
          <Container maxW="container.sm">{PropertyContent()}</Container>
        </SidebarWithHeader>
      ) : (
        <AgentDashboardSidebar showHeader={false}>
          <Container maxW="container.2xl">{PropertyContent()}</Container>
        </AgentDashboardSidebar>
      )}
    </Fragment>
  );
};

export default LandlordPropertyDetails;
