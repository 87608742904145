const states = [
  'Alabama',
  'Arkansas',
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'Washington DC',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
  'Other States',
];

const otherStates = ['Alaska', 'Hawaii'];

const metroAreas = {
  Alabama: [
    'Birmingham',
    'Huntsville',
    'Mobile',
    'Montgomery',
    'Tuscaloosa',
    'Other',
  ],
  Arkansas: ['Fayetteville-Springdale-Rogers', 'Little Rock', 'Other'],
  Arizona: ['Phoenix', 'Tucson', 'Other'],
  California: [
    'Bakersfield',
    'Fresno',
    'Los Angeles',
    'Richmond',
    'Sacramento',
    'San Diego',
    'San Francisco',
    'Stockton',
    'Visalia',
    'Vallejo',
    'Other',
  ],
  Colorado: [
    'Aurora',
    'Colorado Springs',
    'Denver',
    'Fort Collins',
    'Lakewood',
    'Other',
  ],
  Connecticut: [
    'Bridgeport',
    'Stamford',
    'New Haven',
    'Hartford',
    'Waterbury',
    'Other',
  ],
  Delaware: ['Dover', 'Newark', 'Middletown', 'Milford', 'Wilmington', 'Other'],
  Florida: [
    'Deltona',
    'Fort Myers Metro',
    'Jacksonville Metro',
    'Miami Metro',
    'Orlando Metro',
    'Sarasota Metro',
    'St. Petersburg',
    'Tallahassee',
    'Tampa Metro',
    'Other',
  ],
  Georgia: ['Atlanta', 'Augusta', 'Columbus', 'Macon', 'Savannah', 'Other'],
  Idaho: [
    'Boise City',
    'Caldwell',
    'Idaho Falls',
    'Nampa',
    'Meridian',
    'Other',
  ],
  Illinois: [
    'Aurora',
    'Chicago',
    'Joliet',
    'Naperville',
    'Rockford',
    'Springfield',
    'Other',
  ],
  Indiana: [
    'Chicago',
    'Evansville',
    'Fishers',
    'Fort Wayne',
    'Indianapolis',
    'South Bend',
    'Other',
  ],
  Iowa: [
    'Cedar Rapids',
    'Davenport',
    'Des Moines',
    'Iowa City',
    'Sioux City',
    'Other',
  ],
  Kansas: [
    'Kansas City',
    'Olathe',
    'Overland Park',
    'Topeka',
    'Wichita',
    'Other',
  ],
  Kentucky: [
    'Bowling Green',
    'Covington',
    'Frankfort',
    'Lexington',
    'Louisville',
    'Owensboro',
    'Other',
  ],
  Louisiana: [
    'Baton Rouge',
    'Lafayette',
    'Lake Charles',
    'New Orleans',
    'Shreveport',
    'Other',
  ],
  Maine: [
    'Auburn',
    'Augusta',
    'Bangor',
    'Lewiston',
    'Portland',
    'South Portland',
    'Other',
  ],
  Maryland: [
    'Annapolis',
    'Baltimore',
    'Bowie',
    'Frederick',
    'Gaithersburg',
    'Rockville',
    'Salisbury',
    'Other',
  ],
  Massachusetts: ['Boston', 'Cambridge', 'Lowell', 'Springfield', 'Other'],
  Michigan: [
    'Ann Arbor',
    'Detroit',
    'Grand Rapids',
    'Lansing',
    'Sterling Heights',
    'Warren',
    'Other',
  ],
  Minnesota: [
    'Bloomington',
    'Duluth',
    'Minneapolis',
    'Rochester',
    'Saint Paul',
    'Other',
  ],
  Mississippi: [
    'Biloxi',
    'Gulfport',
    'Hattiesburg',
    'Jackson',
    'Southaven',
    'Other',
  ],
  Missouri: [
    'Columbia',
    'Independence',
    'Jefferson City',
    'Kansas City',
    'Saint Louis',
    'Springfield',
    'St Louis',
    'Other',
  ],
  Montana: [
    'Billings',
    'Bozeman',
    'Butte',
    'Great Falls',
    'Helena',
    'Missoula',
    'Other',
  ],
  Nebraska: [
    'Bellevue',
    'Grand Island',
    'Kearney',
    'Lincoln',
    'Omaha',
    'Other',
  ],
  Nevada: [
    'Carson City',
    'Henderson',
    'Las Vegas',
    'North Las Vegas',
    'Reno',
    'Sparks',
    'Other',
  ],
  'New Hampshire': [
    'Concord',
    'Dover',
    'Nashua',
    'Manchester',
    'Rochester',
    'Other',
  ],
  'New Jersey': [
    'Elizabeth',
    'Jersey City',
    'Newark',
    'Paterson',
    'Trenton',
    'Other',
  ],
  'New Mexico': [
    'Albuquerque',
    'Las Cruces',
    'Rio Rancho',
    'Roswell',
    'Santa Fe',
    'Other',
  ],
  'New York': [
    'Albany',
    'Buffalo',
    'New York',
    'Rochester',
    'Syracuse',
    'Yonkers',
    'Other',
  ],
  'North Carolina': [
    'Asheville',
    'Charlotte',
    'Durham',
    'Fayetville',
    'Greensboro',
    'Raleigh',
    'Winston-Salem',
    'Other',
  ],
  'North Dakota': [
    'Bismarck',
    'Fargo',
    'Grand Forks',
    'Minot',
    'West Fargo',
    'Other',
  ],
  Ohio: ['Akron', 'Cincinnati', 'Cleveland', 'Columbus', 'Toledo', 'Other'],
  Oklahoma: [
    'Broken Arrow',
    'Edmond',
    'Oklahama City',
    'Norman',
    'Tulsa',
    'Other',
  ],
  Oregon: ['Eugene', 'Gresham', 'Hillsboro', 'Portland', 'Salem', 'Other'],
  Pennsylvania: [
    'Allentown',
    'Erie',
    'Harrisburg',
    'Philadelphia',
    'Pittsburgh',
    'Other',
  ],
  'Rhode Island': [
    'Cranston',
    'East Providence',
    'Pawtucket',
    'Providence',
    'Warwick',
    'Other',
  ],
  'South Carolina': [
    'Charleston',
    'Charlotte',
    'Columbia',
    'Greenville',
    'North Charleston',
    'Mount Pleasant',
    'Rock Hill',
    'Other',
  ],
  'South Dakota': [
    'Aberdeen',
    'Brookings',
    'Pierre',
    'Rapid City',
    'Sioux Falls',
    'Watertown',
    'Other',
  ],
  Tennessee: [
    'Chattanooga',
    'Clarksville',
    'Knoxville',
    'Nashville',
    'Memphis',
    'Other',
  ],
  Texas: [
    'Austin',
    'Dallas',
    'Fort Worth',
    'Houston',
    'Mcallen',
    'San Antonio',
    'Other',
  ],
  Utah: [
    'Provo',
    'Salt Lake City',
    'St. George',
    'West Jordan',
    'West Valley City',
    'Other',
  ],
  Vermont: [
    'Barre',
    'Burlington',
    'Essex Junction',
    'Montpelier',
    'Rutland',
    'South Burlington',
    'Other',
  ],
  Virginia: [
    'Chesapeake',
    'Newport News',
    'Norfolk',
    'Virginia Beach',
    'Other',
  ],
  Washington: [
    'Bellevue',
    'Olympia',
    'Seattle',
    'Spokane',
    'Tacoma',
    'Vancouver',
    'Other',
  ],
  'Washington DC': ['Washington DC'],
  'West Virginia': [
    'Charleston',
    'Huntington',
    'Morgantown',
    'Parkersburg',
    'Wheeling',
    'Other',
  ],
  Wisconsin: [
    'Chicago',
    'Green Bay',
    'Kenosha',
    'Madison',
    'Milwaukee',
    'Minneapolis-St Paul Metro',
    'Racine',
    'Other',
  ],
  Wyoming: [
    'Casper',
    'Cheyenne',
    'Gillette',
    'Laramie',
    'Rock Springs',
    'Other',
  ],
};

module.exports = {
  states,
  otherStates,
  metroAreas,
};
