import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet-async';

// components
import Navbar from '../components/SearchHomePageComponents/Navbar';
import ShowcaseSection from '../components/SearchHomePageComponents/Showcase/ShowcaseSection';

// utils
import SEO from '../utils/SEO';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
  Heading,
} from '@chakra-ui/react';

const SearchHomes = () => {
  return (
    <>
      <Helmet>
        <title>Search Homes - Utopia Homes</title>
        <meta
          name="description"
          content="Find your perfect home with Utopia Homes' search feature."
        />
      </Helmet>
      <h1 className="sr-only" style={{ display: 'none' }}>
        Search Homes - Utopia Homes
      </h1>
      <Flex direction="column" w="100%" h="100vh">
        <Navbar />
        <ShowcaseSection />
      </Flex>
    </>
  );
};

export default SearchHomes;
