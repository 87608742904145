import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import LandlordMarketingMaterial from '../LandlordMarketingMaterial';

// utils
import { numberWithCommas } from '../../utils/generalFunctions';

// chakra
import {
  Card,
  CardBody,
  Text,
  Stack,
  Flex,
  Badge,
  Box,
  StackDivider,
  CardFooter,
  Spacer,
} from '@chakra-ui/react';

const DisplayPropertyCard = ({
  property,
  userType,
  authUser,
  applicationData,
  navigateUrl,
}) => {
  const navigate = useNavigate();

  return (
    <Card key={property.id} height="fit-content">
      <Box _hover={{ cursor: 'pointer' }} onClick={() => navigate(navigateUrl)}>
        <Carousel
          swipeable={true}
          infiniteLoop={true}
          showThumbs={false}
          autoPlay={true}
          interval={5000}
        >
          {property?.images.map(image => (
            <div key={image.imageUrl}>
              {image.imageUrl.toLowerCase().endsWith('.heic') ? (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: '300px',
                    objectFit: 'cover',
                  }}
                >
                  <h3>HEIC image (preview not available)</h3>
                </div>
              ) : (
                <img
                  src={image?.imageUrl}
                  alt="Property Image"
                  style={{
                    width: '100%',
                    height: '300px',
                    objectFit: 'cover',
                  }}
                />
              )}
              {image.imageDescription && (
                <p className="legend">{image?.imageDescription}</p>
              )}
            </div>
          ))}
        </Carousel>

        <CardBody height="auto" padding="4">
          <Stack spacing={{ base: 5, md: 2 }}>
            {/* Price Information */}
            <Flex
              direction={{ base: 'column', md: 'row' }}
              divider={<StackDivider borderColor="gray.200" />}
            >
              <PriceDisplay
                label="Expected Rent"
                value={property?.data?.stepOne[3]?.answer}
                suffix="/mo"
              />
              <PriceDisplay
                label="Home Price"
                value={property?.data?.stepThree[0]?.answer}
              />
            </Flex>

            {/* Property Details */}
            <Stack
              direction="row"
              divider={<StackDivider borderColor="gray.200" />}
            >
              <PropertyDetail
                value={property?.data?.stepOne[1]?.answer}
                label="bds"
              />
              <PropertyDetail
                value={property?.data?.stepOne[2]?.answer}
                label="ba"
              />
              <PropertyDetail
                value={property?.data?.stepOne[4]?.answer}
                label="sqft"
                formatNumber
              />
            </Stack>

            {/* Property Location Details */}
            <Stack
              direction={{ base: 'column', md: 'row' }}
              divider={<StackDivider borderColor="gray.200" />}
            >
              {[
                property?.data?.stepTwo[3]?.answer,
                property?.data?.stepTwo[0]?.answer,
                property?.data?.stepTwo[1]?.answer,
              ].map((value, index) => (
                <Flex key={index} align="center">
                  <Text fontSize="md" fontWeight="500" color="gray.500">
                    {value || 'N/A'}
                  </Text>
                </Flex>
              ))}
            </Stack>

            <Stack
              direction={{ base: 'column', md: 'row' }}
              divider={<StackDivider borderColor="gray.200" />}
            >
              <Flex align="center">
                <Text fontSize="md" fontWeight="500" color="gray.500" mr="1">
                  Status:
                </Text>
                <Badge
                  fontSize="0.8em"
                  fontWeight="bold"
                  colorScheme={
                    property?.data?.status === 'IN_REVIEW'
                      ? 'yellow'
                      : property?.data?.status === 'ACCEPTED'
                      ? 'green'
                      : 'red'
                  }
                >
                  {property?.data?.status === 'IN_REVIEW'
                    ? 'In Review'
                    : property?.data?.status === 'ACCEPTED'
                    ? 'Accepted'
                    : 'Rejected'}
                </Badge>
              </Flex>

              {property?.data?.status === 'ACCEPTED' && (
                <Flex align="center">
                  <Text fontSize="md" fontWeight="500" color="gray.500" mr="1">
                    Views:
                  </Text>
                  <Text fontWeight="bold">
                    {numberWithCommas(
                      property?.data?.viewCount ? property?.data?.viewCount : 0
                    )}
                  </Text>
                </Flex>
              )}
            </Stack>
          </Stack>
        </CardBody>
      </Box>

      {/* Marketing Material */}
      {property?.data?.status === 'ACCEPTED' && (
        <Box borderColor="gray.200" height="auto" marginTop="0">
          <LandlordMarketingMaterial
            key={property.id}
            propertyId={property.id}
            propertyImage={property?.images[0]?.imageUrl || ''}
            listPrice={
              numberWithCommas(property?.data?.stepThree[0]?.answer) || 'N/A'
            }
            monthlyRent={
              numberWithCommas(property?.data?.stepOne[3]?.answer) || 'N/A'
            }
            bedrooms={property?.data?.stepOne[1]?.answer || 'N/A'}
            bathrooms={property?.data?.stepOne[2]?.answer || 'N/A'}
            sqft={property?.data?.stepOne[4]?.answer || 'N/A'}
            yearBuilt={property?.data?.stepOne[5]?.answer || 'N/A'}
            propertyAddress={property?.data?.stepTwo[3]?.answer || 'N/A'}
            userType={userType}
            agentFirstName={authUser?.data?.legalName?.firstName || ''}
            agentLastName={authUser?.data?.legalName?.lastName || ''}
            agentEmail={authUser?.email || ''}
            agentContactNumber={applicationData?.stepOne[0]?.answer || ''}
            agentBrokerage={applicationData?.stepOne[2]?.answer || ''}
          />
        </Box>
      )}
    </Card>
  );
};

// Helper Components
const PriceDisplay = ({ label, value, suffix = '' }) => (
  <Flex align="center" mr="4">
    <Text fontSize="lg" fontWeight="500" color="gray.500" mr="2">
      {label}:
    </Text>
    <Text fontSize="lg" fontWeight="700">
      ${value ? numberWithCommas(value) : 'N/A'}
      {suffix}
    </Text>
  </Flex>
);

const PropertyDetail = ({ value, label, formatNumber }) => (
  <Flex align="center">
    <Text fontSize="md" fontWeight="700" mr="1">
      {formatNumber ? numberWithCommas(value) : value || 'N/A'}
    </Text>
    <Text fontSize="md" fontWeight="500" color="gray.500">
      {label}
    </Text>
  </Flex>
);

export default DisplayPropertyCard;
