import React, { Fragment, useState, useEffect } from 'react';

// context
import { useLandlord } from '../../../context/LandlordContext';

// components
import ShowcaseCard from './ShowcaseCard';
import Footer from '../../ListingPageComponents/Footer';

// utils
import { LowerFourtyEightList } from '../../../utils/states';

// chakra
import {
  Box,
  Flex,
  Text,
  Container,
  SimpleGrid,
  Card,
  CardBody,
  Spinner,
  Select,
  Image,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';

const ShowcaseSection = () => {
  const { getAcceptedProperties_Truncated, getAcceptedProperties_Full } =
    useLandlord();

  const [selectedState, setSelectedState] = useState('Texas');

  const [truncatedProperties, setTruncatedProperties] = useState([]);
  const [fullProperties, setFullProperties] = useState([]);
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);

  useEffect(() => {
    let isCurrent = true;

    const fetchProperties = async () => {
      try {
        setPropertiesLoaded(false);
        setFullProperties([]); // Reset full properties immediately

        // Get truncated properties first
        const truncatedList = await getAcceptedProperties_Truncated(
          selectedState
        );
        if (!isCurrent) return; // Don't update if we've unmounted or changed state
        setTruncatedProperties(truncatedList);
        setPropertiesLoaded(true);

        // Then get full properties without blocking
        getAcceptedProperties_Full(selectedState)
          .then(fullList => {
            if (isCurrent) {
              // Only update if we're still on the same state
              setFullProperties(fullList);
            }
          })
          .catch(err => {
            if (isCurrent) {
              console.log('Error fetching full properties:', err);
            }
          });
      } catch (err) {
        if (isCurrent) {
          console.log('Error fetching truncated properties:', err);
          setPropertiesLoaded(true);
        }
      }
    };

    fetchProperties();

    // Cleanup function
    return () => {
      isCurrent = false; // Mark as stale when state changes or component unmounts
    };
  }, [selectedState]);

  return (
    <Fragment>
      <Flex bg="gray.100" direction="column">
        <Container maxW="container.xl">
          <Flex py="5" align="center">
            <Text fontSize="md" fontWeight="600" color="gray.600">
              {propertiesLoaded ? (
                <>
                  {fullProperties.length === 0
                    ? `${truncatedProperties.length} homes in`
                    : `${fullProperties.length} homes in`}
                </>
              ) : (
                'Loading properties in '
              )}
            </Text>
            <Select
              variant="outline"
              maxW="200px"
              ml="2"
              size="lg"
              value={selectedState}
              onChange={e => setSelectedState(e.target.value)}
              isDisabled={!propertiesLoaded}
            >
              {LowerFourtyEightList.map(state => (
                <option key={state.abbreviation} value={state.name}>
                  {state.name}
                </option>
              ))}
            </Select>
          </Flex>
        </Container>
      </Flex>
      {propertiesLoaded ? (
        <Flex bg="gray.100" direction="column" flex={1} overflow="auto">
          <Container maxW="container.xl" mb="8">
            {truncatedProperties.length === 0 ? (
              <Flex>
                <Container maxW="container.sm">
                  <Card align="center" bg="white" size="md" rounded="7px">
                    <Flex>
                      <Image
                        borderRadius="7px 7px 0px 0px"
                        src="https://i.imgur.com/YO5hFii.jpg"
                        maxW="100%"
                      />
                    </Flex>
                    <CardBody align="center">
                      <Text fontSize="md" fontWeight="600">
                        Unfortunately, no homes found in {selectedState}
                      </Text>
                      <Box mt="3">
                        <Text fontSize="sm" fontWeight="400" color="gray">
                          Have a home in {selectedState} you're interesting in
                          having on the Utopia platform? Contact us.
                        </Text>
                        <Flex align="center" justify="center" w="100%">
                          <Text fontSize="xl" fontWeight="600" color="gray">
                            support@utopia.cx
                          </Text>
                        </Flex>
                      </Box>
                    </CardBody>
                  </Card>
                </Container>
              </Flex>
            ) : (
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                {fullProperties.length === 0 ? (
                  <>
                    {truncatedProperties.map(property => (
                      <div key={property.id}>
                        <ShowcaseCard
                          id={property.id}
                          property={property.data}
                          images={property.images}
                          autoPlay={false}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {fullProperties.map(property => (
                      <div key={property.id}>
                        <ShowcaseCard
                          id={property.id}
                          property={property.data}
                          images={property.images}
                          autoPlay={true}
                        />
                      </div>
                    ))}
                  </>
                )}
              </SimpleGrid>
            )}
          </Container>
          <Footer />
        </Flex>
      ) : (
        <Flex bg="gray.100" justify="center" align="center" flex={1}>
          <Spinner size="lg" />
        </Flex>
      )}
    </Fragment>
  );
};

export default ShowcaseSection;
