import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAgentAuth } from '../../../context/AgentContext';
import AgentInvitationButton from '../AgentInvitation';
import ClientInvitationButton from '../ClientInvitation';
import LandlordInvitationButton from '../LandlordInvitation';

const AgentsHeader = ({ showInvite, showSearch }) => {
  const location = useLocation();
  const {
    invitedUsers,
    setSearchresults,
    startedApplications,
    offersData,
    closingsData,
    approvedApplications,
    conditionallyApprovedApplications,
    preQualifiedApplications,
    pausedApplications,
    rejectedApplications,
    invitedAgents,
    allUsers,
    initiatedOffers,
  } = useAgentAuth();

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter(value => {
      return (
        value.name?.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
        value.email?.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
        value.clientName?.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
        value.clientEmail?.toLowerCase().match(new RegExp(searchTerm, 'g'))
      );
    });
  };

  const handleSearch = searchText => {
    let pathname = location.pathname;

    let arr = [];

    if (pathname === '/agents/clients') {
      arr = allUsers;
    } else if (pathname === '/agents/invited') {
      arr = invitedUsers;
    } else if (pathname === '/agents/applications/application_started') {
      arr = startedApplications;
    } else if (pathname === '/agents/applications/prequalified') {
      arr = preQualifiedApplications;
    } else if (pathname === '/agents/applications/conditionally_approved') {
      arr = conditionallyApprovedApplications;
    } else if (pathname === '/agents/applications/fully_approved') {
      arr = approvedApplications;
    } else if (pathname === '/agents/closings/inspection_complete') {
      arr = closingsData?.inspectionComplete;
    } else if (pathname === '/agents/closings/preclosing_complete') {
      arr = closingsData?.preclosingComplete;
    } else if (pathname === '/agents/offers/offer_accepted') {
      arr = offersData?.offerAccepted;
    } else if (pathname === '/agents/offers/offer_extended') {
      arr = offersData?.offerExtended;
    } else if (pathname === '/agents/closings/offer_closed') {
      arr = closingsData?.offerClosed;
    } else if (pathname === '/agents/rejected') {
      arr = rejectedApplications;
    } else if (pathname === '/agents/paused') {
      arr = pausedApplications;
    } else if (pathname === '/agents/invites') {
      arr = invitedAgents;
    } else if (pathname === '/agents/offers/initiated') {
      arr = initiatedOffers;
    }

    const searchRes = arraySearch(arr, searchText);
    setSearchresults(searchRes);
  };

  useEffect(() => {
    setSearchresults(null);
  }, [location.pathname]);
  return (
    <Box>
      <Flex pb={5}>
        {showSearch && (
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
              children={<SearchIcon />}
            />
            <Input
              placeholder="Search"
              type="search"
              onChange={e => handleSearch(e.target?.value)}
              mr={2}
              minW="65vw"
            />
          </InputGroup>
        )}

        <Spacer />

        <Flex maxW="400px">
          {showInvite && location.pathname === '/agents/invites' ? (
            <AgentInvitationButton />
          ) : showInvite && location.pathname === '/agents/landlord/invites' ? (
            <LandlordInvitationButton />
          ) : (
            <ClientInvitationButton />
          )}
        </Flex>
      </Flex>
      <Divider color={'red'} mb={5} />
    </Box>
  );
};

export default AgentsHeader;
