import React, { Fragment } from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';

// components
import TechRichInput from '../../../../components/TextRich/TechRichInput';

// chakra
import {
  Button,
  Card,
  CardBody,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  Progress,
  Stack,
  Flex,
  Select,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  Textarea,
} from '@chakra-ui/react';

const AddPropertyStepFour = ({
  setCurrentStep,
  handleNext,
  isLoading,
  stepFourPropertyDescription,
}) => {
  return (
    <Fragment>
      <Formik
        initialValues={{
          propertyDescription: stepFourPropertyDescription,
        }}
        validationSchema={yup.object({
          propertyDescription: yup
            .string()
            .min(50, 'Your response should at least be 50 characters long')
            .max(7000, 'Your response should be less than 7000 characters long')
            .required('Required'),
        })}
        onSubmit={async values => {
          try {
            await handleNext({
              propertyDescription: values.propertyDescription,
            });
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing="5">
              <Card bg="white" size="lg">
                <CardBody>
                  <Heading as="h4" size="md" mb="5">
                    Add Property Description
                  </Heading>
                  <Stack spacing="5">
                    <FormControl
                      isInvalid={
                        formik.errors.propertyDescription &&
                        formik.touched.propertyDescription
                      }
                      isDisabled={isLoading}
                    >
                      <FormLabel color="gray">
                        Describe the Property, Subdivision and Neighborhood
                      </FormLabel>
                      <TechRichInput
                        value={formik.values.propertyDescription}
                        setValue={value => {
                          formik.setFieldValue('propertyDescription', value);
                        }}
                        placeholder="Write several sentences describing the Property, Subdivision and Neighborhood. Make sure to mention desirable features that may attract renters/future home buyers like school district, club house, easy access to business district, downtown etc."
                      />
                      <FormHelperText>
                        Response must be at least 50 characters long
                      </FormHelperText>
                      <FormErrorMessage>
                        {formik.errors.propertyDescription}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </CardBody>
              </Card>
              <Stack justify="right" direction="row" spacing="3">
                <Button
                  onClick={() => {
                    setCurrentStep(3);
                  }}
                  isLoading={isLoading}
                >
                  Prev
                </Button>
                <Button colorScheme="blue" type="submit" isLoading={isLoading}>
                  Next
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AddPropertyStepFour;
