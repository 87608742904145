import React, { Fragment } from 'react';

// chakra
import {
  Text,
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Divider,
  Button,
  Stack,
  StackDivider,
  Image,
  Spacer,
  Link as ChakraLink,
  IconButton,
  Icon,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaTwitter,
  FaInstagram,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <Fragment>
      <Box w="100%" bg="gray.700">
        <Container maxW="1500px" color="white">
          <Box pt="8" pb="16">
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Stack mb={{ base: '8', md: '0' }}>
                <Image
                  src="https://phvpvziphcouosuyhdre.supabase.co/storage/v1/object/public/images/Group%2052%20(2)%202%20(1).png"
                  maxW="100px"
                />
                <Text fontSize="12px" fontWeight="800" mb="2" maxW="200px">
                  Making homeownership accessible to all
                </Text>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => {
                    window.location.href =
                      'https://dash.utopiahomes.us/signup/landlord';
                  }}
                >
                  <Text>Get Started Now</Text>
                </Button>
              </Stack>
              <Spacer display={{ base: 'none', md: 'block' }} />
              <Stack mr={{ base: '0', md: '16' }} mb={{ base: '8', md: '0' }}>
                <Text fontSize="sm" fontWeight="800" mb="2">
                  COMPANY
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/how-it-works"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    How it works: Homebuyer
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/landlords/how-it-works"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    How it works: Landlords
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://investors.utopiahomes.us/how-it-works"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    How it works: Investor
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/about-us"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    About us
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="/search-homes"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Search Homes
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/faq/general-questions"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    FAQ: Homebuyer
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/faq/general-questions"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    FAQ: Landlords
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://investors.utopiahomes.us/faq"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    FAQ: Investor
                  </ChakraLink>
                </Text>
              </Stack>

              <Stack mr={{ base: '0', md: '16' }} mb={{ base: '8', md: '0' }}>
                <Text fontSize="sm" fontWeight="800" mb="2">
                  RESOURCES
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href={`mailto:hello@utopia.cx?subject=I've%20got%20a%20question`}
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Contact Us
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/landlords"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Landlords
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/agents"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Agents
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/lenders"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Lenders
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://investors.utopiahomes.us/"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Investors
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://blog.utopiahomes.us/"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Blogs: Homebuyer
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://investors.utopiahomes.us/blogs"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Blogs: Investor
                  </ChakraLink>
                </Text>
              </Stack>

              <Stack>
                <Text fontSize="sm" fontWeight="800" mb="2">
                  LEGAL
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/terms"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Terms
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/privacy"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Privacy
                  </ChakraLink>
                </Text>

                <Text fontSize="sm" fontWeight="500">
                  <ChakraLink
                    href="https://www.utopiahomes.us/policy"
                    target="_blank"
                    color="white"
                    _hover={{
                      textDecoration: 'underline',
                    }}
                  >
                    Policy
                  </ChakraLink>
                </Text>
              </Stack>
            </Flex>
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }} py="4" align="center">
            <Text fontSize="sm" fontWeight="600" mb={{ base: '2', md: '0' }}>
              ©2024 RevEx Technologies, Inc
            </Text>
            <Spacer display={{ base: 'none', md: 'block' }} />
            <Stack direction="row">
              <Link
                href="https://www.linkedin.com/company/utopiabuy/about"
                target="_blank"
              >
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="Search database"
                  color="white"
                  _hover={{ color: 'black', bg: 'white' }}
                  _active={{ color: 'black', bg: 'white' }}
                  icon={<Icon as={FaLinkedinIn} />}
                  rounded="full"
                  size="sm"
                />
              </Link>

              <Link href="https://www.facebook.com/UtopiaBuy" target="_blank">
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="Search database"
                  color="white"
                  _hover={{ color: 'black', bg: 'white' }}
                  _active={{ color: 'black', bg: 'white' }}
                  icon={<Icon as={FaFacebookF} />}
                  rounded="full"
                  size="sm"
                />
              </Link>

              <Link href="https://www.instagram.com/utopiabuy/" target="_blank">
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="Search database"
                  color="white"
                  _hover={{ color: 'black', bg: 'white' }}
                  _active={{ color: 'black', bg: 'white' }}
                  icon={<Icon as={FaInstagram} />}
                  rounded="full"
                  size="sm"
                />
              </Link>

              <Link
                href="https://www.youtube.com/channel/UCwxWY_3j_Qmpr7RmRavScuw"
                target="_blank"
              >
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="Search database"
                  color="white"
                  _hover={{ color: 'black', bg: 'white' }}
                  _active={{ color: 'black', bg: 'white' }}
                  icon={<Icon as={FaYoutube} />}
                  rounded="full"
                  size="sm"
                />
              </Link>

              <Link href="https://twitter.com/UtopiaBuy" target="_blank">
                <IconButton
                  variant="ghost"
                  colorScheme="gray"
                  aria-label="Search database"
                  color="white"
                  _hover={{ color: 'black', bg: 'white' }}
                  _active={{ color: 'black', bg: 'white' }}
                  icon={<Icon as={FaTwitter} />}
                  rounded="full"
                  size="sm"
                />
              </Link>
            </Stack>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  );
};

export default Footer;
