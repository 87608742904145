import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Show,
  Hide,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ClientInvitationButton from '../../../components/Agents/ClientInvitation';
import ClientReminderButton from '../../../components/Agents/ClientReminder';
import { useAgentAuth } from '../../../context/AgentContext';
import { useAuth } from '../../../context/AuthContext';
import AgentDashboardSidebar from '../../Sidebar';

const AgentHome = () => {
  const navigate = useNavigate();

  const { authUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isLargerThan1280] = useMediaQuery('(min-width: 1100px)');
  const [welcomeModalVisible, setWelcomeModalVisible] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const location = useLocation();

  const [agentsInvited, setAgentsInvited] = useState([]);
  const [agentsJoined, setAgentsJoined] = useState([]);

  const [landlordsInvited, setLandlordsInvited] = useState([]);
  const [landlordsJoined, setLandlordsJoined] = useState([]);

  let searchParams = new URLSearchParams(location.search);
  let isWelcomed = searchParams.get('welcome');

  const {
    approvedApplications,
    preQualifiedApplications,
    startedApplications,
    invitedUsers,
    updateAllApplicationsData,
    conditionallyApprovedApplications,
    offersData,
    inactive,
    pausedApplications,
    rejectedApplications,
    invitedLandlords,
    invitedAgents,
  } = useAgentAuth();

  const initialDataUpdate = () => {
    setLoading(true);
    updateAllApplicationsData(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    initialDataUpdate();

    if (isWelcomed) {
      setWelcomeModalVisible(true);
    }

    let intervalId;
    let prevTime = Date.now();

    const updateInterval = () => {
      const currTime = Date.now();
      const elapsedTime = currTime - prevTime;
      const interval = elapsedTime < 120000 ? 120000 - elapsedTime : 0;
      intervalId = setTimeout(() => {
        initialDataUpdate();
        prevTime = Date.now();
        updateInterval();
      }, interval);
    };

    updateInterval();

    return () => clearTimeout(intervalId);
  }, []);

  useEffect(() => {
    let agents_invited = [];
    let agents_joined = [];
    for (let i = 0; i < invitedAgents.length; i++) {
      if (invitedAgents[i]?.applicationStatus === 'Invited') {
        agents_invited.push(invitedAgents[i]);
      } else if (invitedAgents[i]?.applicationStatus === 'Joined') {
        agents_joined.push(invitedAgents[i]);
      }
    }
    setAgentsInvited(agents_invited);
    setAgentsJoined(agents_joined);
  }, [invitedAgents]);

  useEffect(() => {
    let landlords_invited = [];
    let landlords_joined = [];
    for (let i = 0; i < invitedLandlords.length; i++) {
      if (invitedLandlords[i].applicationStatus === 'Invited') {
        landlords_invited.push(invitedLandlords[i]);
      } else if (invitedLandlords[i].applicationStatus === 'Joined') {
        landlords_joined.push(invitedLandlords[i]);
      }
    }
    setLandlordsInvited(landlords_invited);
    setLandlordsJoined(landlords_joined);
  }, [invitedLandlords]);

  const followUpClientsLength = invitedUsers.length;

  const ClientTab = ({ data }) => {
    return (
      <Fragment>
        <FeedComponent
          title="Invited"
          description="Thanks for email inviting these clients to Utopia. Please help them get started with their free application (takes <5 mins)"
          placeholder="No active clients"
          data={invitedUsers}
          loading={loading}
          route="/agents/applications/invited"
          userType="homebuyer"
        />
        <FeedComponent
          title="Application started"
          description="Your clients just signed up with Utopia. Please help them get pre-qualified to receive their home buying budget."
          placeholder="No active clients"
          data={startedApplications}
          loading={loading}
          route="/agents/applications/application_started"
          userType="homebuyer"
        />
        <FeedComponent
          title="Prequalified"
          description="Congrats, Your clients are pre-qualified. Please help them verify their details so they can begin home shopping!"
          placeholder="No active clients"
          data={preQualifiedApplications}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/applications/prequalified"
          userType="homebuyer"
        />
        <FeedComponent
          title="More Information Required"
          description="We require additonal documents or information for the application from the client. Please help them get that to us to receive their home buying budget"
          placeholder="No clients found"
          data={offersData?.moreInfoRequired}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/applications/more_info"
          userType="homebuyer"
        />
        <FeedComponent
          title="In-Review"
          description="Underwriters are reviewing the application as we speak."
          placeholder="No clients found"
          data={offersData?.inreview}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/applications/inreview"
          userType="homebuyer"
        />
        <FeedComponent
          title="Conditionally approved"
          description="Almost there! You can now submit an offer on the clients' dream home or Utopia."
          placeholder="No active clients"
          data={conditionallyApprovedApplications}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/applications/conditionally_approved"
          userType="homebuyer"
        />
        <FeedComponent
          title="Fully approved"
          description="The client is fully approved to get their Utopia!"
          placeholder="No active clients"
          data={approvedApplications}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/applications/fully_approved"
          userType="homebuyer"
        />
        <FeedComponent
          title="Offer extended"
          description="Utopia has extended offers to the sellers and awaiting response."
          placeholder="No active clients"
          data={offersData?.offerExtended}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/offers/offer_extended"
          userType="homebuyer"
        />
        <FeedComponent
          title="Offer accepted"
          description="Congratulations, these offers have been accepted! Now it’s time for us to start the inspection negotiation process."
          placeholder="No active clients"
          data={offersData?.offerAccepted}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/offers/offer_accepted"
          userType="homebuyer"
        />
        <FeedComponent
          title="Inactive"
          description="These applications have been Inactive."
          placeholder="No active clients"
          data={inactive}
          hasReminder={false}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/inactive"
          userType="homebuyer"
        />
        <FeedComponent
          title="Paused"
          description="These applications have been Paused. For privacy reasons, we can't share the reasons. However, feel free to connect with your clients who can share that information."
          placeholder="No active clients"
          data={pausedApplications}
          hasReminder={false}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/paused"
          userType="homebuyer"
        />
        <FeedComponent
          title="Rejected"
          description="These applications have been Rejected. For privacy reasons, we can't share the reasons. However, feel free to connect with your clients who can share that information."
          placeholder="No active clients"
          data={rejectedApplications}
          hasReminder={false}
          loading={loading}
          buttonTitle="E-Mail Client"
          route="/agents/rejected"
          userType="homebuyer"
        />
      </Fragment>
    );
  };

  const LandlordTab = ({ data }) => {
    return (
      <Fragment>
        <FeedComponent
          title="Invited"
          description="Thanks for email inviting these landlords to Utopia. Please help them get started with their free application (takes <5 mins)"
          placeholder="No active landlords"
          data={landlordsInvited}
          loading={loading}
          route="/agents/applications/invited"
          userType="landlord"
        />
        <FeedComponent
          title="Joined"
          description="These applications have been Completed. These landlords can now start uploading properties to Utopia."
          placeholder="No active landlords"
          data={landlordsJoined}
          hasReminder={false}
          loading={loading}
          buttonTitle="E-Mail Landlord"
          route="/agents/rejected"
          userType="landlord"
        />
      </Fragment>
    );
  };

  const AgentTab = ({ data }) => {
    return (
      <Fragment>
        <FeedComponent
          title="Invited"
          description="Thanks for email inviting these agents to Utopia. Please help them get started with their free application (takes <5 mins)"
          placeholder="No active agents"
          data={agentsInvited}
          loading={loading}
          route="/agents/applications/invited"
          userType="agent"
        />
        <FeedComponent
          title="Joined"
          description="These applications have been Completed. These landlords can now start uploading properties to Utopia."
          placeholder="No active landlords"
          data={agentsJoined}
          hasReminder={false}
          loading={loading}
          buttonTitle="E-Mail Agent"
          route="/agents/rejected"
          userType="agent"
        />
      </Fragment>
    );
  };

  return (
    <AgentDashboardSidebar showHeader={false}>
      <Flex
        // flexDirection={{ base: 'column', md: 'row', sm: 'column' }}
        flexDirection={isLargerThan1280 ? 'row' : 'column'}
        alignItems={{ base: 'center', md: 'flex-start' }}
      >
        <Box ml={{ base: 0, md: '12%' }} mt={10} maxW={'80vw'}>
          <Text>Welcome Back, {authUser?.data?.legalName?.firstName}!</Text>
          <Heading fontSize={'26px'}>
            You have {followUpClientsLength} clients, {landlordsInvited.length}{' '}
            landlords, and {agentsInvited.length} agents to follow up with.
          </Heading>
          <Flex my={6} flexDirection={{ base: 'column', md: 'row' }}>
            <Button
              backgroundColor={'#1C2430'}
              textColor={'white'}
              minW={30}
              px={10}
              _hover={{
                bg: 'black',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/agents/invite')}
            >
              Invite & Earn
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                w={{ base: '100%', md: 'auto' }}
                ml={{ base: 0, md: 4 }}
                mt={{ base: 2, md: 0 }}
                fontSize="sm"
                colorScheme="blue"
              >
                Marketing Material
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => navigate('/agents/marketing')}>
                  To Get Homebuyers
                </MenuItem>
                <MenuItem
                  onClick={() => navigate('/agents/landlord/marketing')}
                >
                  To Get Landlords
                </MenuItem>
                <MenuItem onClick={() => navigate('/agents/properties')}>
                  To Promote Listings
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <Tabs
            mt="4"
            variant="soft-rounded"
            size="lg"
            onChange={index => setTabIndex(index)}
          >
            <TabList
              overflowX="auto"
              whiteSpace="nowrap"
              css={{
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': { display: 'none' },
              }}
            >
              <Tab>Clients</Tab>
              <Tab>Landlords</Tab>
              <Tab>Agents</Tab>
            </TabList>

            <TabPanels>
              <TabPanel borderRadius="0px 10px 10px 10px">
                <ClientTab />
              </TabPanel>
              <TabPanel borderRadius="0px 10px 10px 10px">
                <LandlordTab />
              </TabPanel>
              <TabPanel borderRadius="0px 10px 10px 10px">
                <AgentTab />
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Hide above="md">
            <InformationalCard width={'100%'} margin={'0 0 40px 0'} />
          </Hide>
        </Box>

        <Show above="md">
          <Flex
            w={'100%'}
            alignItems={{ base: 'flex-start', md: 'center' }}
            justifyContent="center"
            maxH={'100vh'}
            mt={{ base: 5, md: 0 }}
            height={'100vh'}
            position="sticky"
            top={0}
          >
            <InformationalCard width={{ base: '100%', md: '25vw' }} />
          </Flex>
        </Show>
      </Flex>

      <WelcomePopup
        visible={welcomeModalVisible}
        closeAction={() => {
          setWelcomeModalVisible(false);
        }}
      />
    </AgentDashboardSidebar>
  );
};

const FeedComponent = ({
  title,
  description,
  placeholder,
  data,
  hasReminder = true,
  loading,
  buttonTitle,
  route,
  userType = 'homebuyer',
}) => {
  const navigate = useNavigate();
  return (
    <Box mt={10}>
      <Flex>
        <Text size={'md'} fontWeight="semibold" fontSize="xl">
          {title}
        </Text>
        <NavLink to={route}>
          <Text size={'md'} fontSize="xl" marginLeft={1} color={'blue.400'}>
            <u>({data?.length})</u>
          </Text>
        </NavLink>
      </Flex>

      <Text mb={2}>{description}</Text>

      {data?.length > 0 ? (
        <>
          {data.slice(0, 3).map((item, index) => {
            return (
              <Card
                cursor={'pointer'}
                key={index}
                backgroundColor={'white'}
                mt={2}
              >
                <CardHeader>
                  <Flex
                    spacing="4"
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Flex
                      flex="1"
                      gap="4"
                      alignItems="center"
                      flexDirection={{
                        base: 'column',
                        md: 'row',
                      }}
                    >
                      <Avatar
                        name={item.name}
                        src={item.profileImage}
                        onClick={() => {
                          navigate(
                            `/agents/application_details?client_email=${encodeURIComponent(
                              item?.email
                            )}`
                          );
                        }}
                      />

                      <Flex
                        flexDirection="column"
                        flex={1}
                        alignItems={{
                          base: 'center',
                          md: 'flex-start',
                        }}
                        onClick={() => {
                          navigate(
                            `/agents/application_details?client_email=${encodeURIComponent(
                              item?.email
                            )}`
                          );
                        }}
                      >
                        <Heading size="sm">{item.name}</Heading>
                        <Text fontSize={'sm'}>
                          {userType === 'homebuyer'
                            ? 'Client'
                            : userType === 'agent'
                            ? 'Agent'
                            : 'Landlord'}{' '}
                          Action Needed
                        </Text>

                        <Text fontSize={'xs'} color={'gray.500'}>
                          E-Mailed: {item.inviteSent}
                        </Text>
                      </Flex>

                      <Flex flex={1}>
                        {hasReminder && (
                          <ClientReminderButton
                            data={item}
                            title={buttonTitle}
                            isAgent={userType === 'agent'}
                            isLandlord={userType === 'landlord'}
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </CardHeader>
              </Card>
            );
          })}
        </>
      ) : (
        <Box
          backgroundColor="#E8E9EA"
          minHeight={'10vh'}
          borderRadius={10}
          display="flex"
          alignItems="center"
          px={5}
        >
          {loading ? (
            <Flex flex={1} alignItems="center" justifyContent={'center'}>
              <Spinner />
            </Flex>
          ) : (
            <Heading size={'md'} color="gray.400">
              {placeholder}
            </Heading>
          )}
        </Box>
      )}

      {data?.length > 0 && (
        <NavLink to={route}>
          <Text cursor={'pointer'} colorScheme="blue" color={'blue.400'}>
            See More...
          </Text>
        </NavLink>
      )}
    </Box>
  );
};

const WelcomePopup = ({ visible = false, closeAction }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: visible,
    onClose: closeAction,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const toast = useToast();

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={{ base: 'full', md: 'xl' }}
        zIndex={1}
      >
        <ModalOverlay />
        <ModalContent minH="50vh">
          <ModalHeader>Welcome</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody
            display="flex"
            alignItems={'center'}
            justifyContent="center"
          >
            {/* <Container maxW="container.sm"> */}
            <Stack spacing="5">
              <Card align="center" bg="white" size="md">
                <CardHeader>
                  <Image
                    boxSize="100px"
                    objectFit="cover"
                    src="https://i.imgur.com/NwzpGQ9.png"
                    alt="Dan Abramov"
                  />
                </CardHeader>
                <CardBody align="center">
                  <Heading as="h4" size="md" mb="1">
                    Thank you for joining Utopia Agent Network.
                  </Heading>
                  <Text fontSize="md" fontWeight="600" color="gray">
                    Start inviting clients who are not yet mortgage ready for
                    our innovative "Rent, Earn, and Own" Program. Your clients
                    can move in to their dream home as early as 21 days and you
                    earn your full commissions.
                  </Text>
                </CardBody>
              </Card>
            </Stack>
            {/* </Container> */}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const InformationalCard = ({ width = 'auto', margin = '0' }) => {
  return (
    <Box
      backgroundColor={'white'}
      p={10}
      borderRadius={10}
      width={width}
      margin={margin}
    >
      <Heading>Earn more while helping renters, buyers & landlords</Heading>

      <Text mt={4}>
        With Utopia, as a real estate agent, you can help clients who aren’t
        mortgage-ready secure their dream homes while enabling your landlord
        clients to rent and sell properties faster. Earn full commissions,
        unlock generous incentives, and boost your success rate with quicker,
        hassle-free closings.
      </Text>
    </Box>
  );
};

export default AgentHome;
