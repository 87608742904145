import React, { useState, useEffect, useRef, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextRichView from '../components/TextRich/TextRichView';
import TechRichInput from '../components/TextRich/TechRichInput';
import FaqCard from '../components/FaqCard';
import { createSwapy, utils, SlotItemMapArray, Swapy } from 'swapy';

import {
  Box,
  Button,
  Card,
  CardBody,
  Text,
  Grid,
  GridItem,
  Stack,
  StackDivider,
  Divider,
  List,
  ListItem,
  UnorderedList,
  OrderedList,
  Container,
  Flex,
  Image,
  Link,
  Spacer,
  Textarea,
} from '@chakra-ui/react';

const TesterPage = () => {
  const initialItems = [
    { id: '1', title: '1' },
    { id: '2', title: '2' },
    { id: '3', title: '3' },
  ];

  let id = 4;

  const [items, setItems] = useState(initialItems);
  const [slotItemMap, setSlotItemMap] = useState(
    utils.initSlotItemMap(items, 'id')
  );
  const slottedItems = useMemo(
    () => utils.toSlottedItems(items, 'id', slotItemMap),
    [items, slotItemMap]
  );
  const swapyRef = useRef(null);

  const containerRef = useRef(null);

  useEffect(
    () =>
      utils.dynamicSwapy(
        swapyRef.current,
        items,
        'id',
        slotItemMap,
        setSlotItemMap
      ),
    [items]
  );

  useEffect(() => {
    swapyRef.current = createSwapy(containerRef.current, {
      manualSwap: true,
    });

    swapyRef.current.onSwap(event => {
      setSlotItemMap(event.newSlotItemMap.asArray);
    });

    return () => {
      swapyRef.current?.destroy();
    };
  }, []);

  return (
    <Flex direction="column" align="center" justify="center">
      <Flex
        direction="column"
        ref={containerRef}
        width="600px"
        background="gray.100"
        mt="4"
        p="4"
      >
        <Flex>
          <Spacer />
          <Button
            mb="8"
            colorScheme="blue"
            onClick={() => {
              const newItem: Item = { id: `${id}`, title: `${id}` };
              setItems([...items, newItem]);
              id++;
            }}
          >
            Add Item
          </Button>
        </Flex>

        <Flex className="users" direction="column" gap="3">
          {slottedItems.map(({ slotId, itemId, item }) => (
            <Box className="slot" key={slotId} data-swapy-slot={slotId}>
              {item && (
                <Box
                  className="item"
                  data-swapy-item={itemId}
                  key={itemId}
                  cursor="move"
                >
                  <Card background="white">
                    <CardBody>
                      <Flex justify="space-between" align="center">
                        <Image
                          boxSize="200px"
                          objectFit="cover"
                          src="https://bit.ly/dan-abramov"
                          alt="Dan Abramov"
                          mr="4"
                        />
                        <Flex direction="column" width="100%" height="100%">
                          <Text>{item.title}</Text>
                          <Textarea
                            placeholder="Here is a sample placeholder"
                            w="100%"
                          />
                          <Spacer />
                          <Button
                            colorScheme="red"
                            onClick={() => {
                              setItems(items.filter(i => i.id !== item.id));
                            }}
                          >
                            Delete
                          </Button>
                        </Flex>
                      </Flex>
                    </CardBody>
                  </Card>
                </Box>
              )}
            </Box>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TesterPage;
